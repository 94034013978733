import React, { Component } from 'react';
import { Button, Row } from 'antd';
import Question from '../suite/Question';
import Reponses from '../suite/Reponses';
export default class TestSuite extends Component {


    constructor(props) {
        super(props);
        this.tabQuestion = [{type : "art", temps : 300}, {type : "art", temps : 600},{type : "art", temps : 1200},{type : "art", temps : 2400},{type : "art", temps : 3600},
        {type : "culture", temps : 300}, {type : "culture", temps : 600},{type : "culture", temps : 1200},{type : "culture", temps : 2400},{type : "culture", temps : 3600},
        {type : "logique", temps : 300}, {type : "logique", temps : 600},{type : "logique", temps : 1200},{type : "logique", temps : 2400},{type : "logique", temps : 3600},
        {type : "divertissement", temps : 300}, {type : "divertissement", temps : 600},{type : "divertissement", temps : 1200},{type : "divertissement", temps : 2400},{type : "divertissement", temps : 3600},
        {type : "loisir", temps : 300}, {type : "loisir", temps : 600},{type : "loisir", temps : 1200},{type : "loisir", temps : 2400},{type : "loisir", temps : 3600}
    ];
        this.state = {
            listeQuestions : [],
            afficheReponse: false,
            question: '',
            reponseTab : [],
            bonneReponse : 0,
        }


        
    }

   afficherQuestions = async (event) =>
    {
        const id = event.currentTarget.id;
        let url = new URL(process.env.REACT_APP_URL_QUIZOBTENIRQUESTIONSTEST);
        var data = new FormData();
        data.append('temps', this.tabQuestion[id].temps);
        data.append('type', this.tabQuestion[id].type);
        fetch(url, {
            method: "POST",
            body: data
        }).then(res => res.json())
            .then(res => {
                this.donneesJeu = res;
                this.setState({ listeQuestions: this.donneesJeu });console.log(this.donneesJeu);
            }
            );
            
    }

  

    render() {
        return <div>
            
            <div>
           <h3>Art</h3>
           <Button id="0" onClick={this.afficherQuestions}>300</Button><Button id="1" onClick={this.afficherQuestions}>600</Button><Button id="2" onClick={this.afficherQuestions}>1200</Button><Button id="3" onClick={this.afficherQuestions}>2400</Button>  <Button id="4" onClick={this.afficherQuestions}>3600</Button>
           <h3>culture</h3>
           <Button id="5" onClick={this.afficherQuestions}>300</Button><Button id="6" onClick={this.afficherQuestions}>600</Button><Button id="7" onClick={this.afficherQuestions}>1200</Button><Button id="8" onClick={this.afficherQuestions}>2400</Button>  <Button id="9" onClick={this.afficherQuestions}>3600</Button>
           <h3>logique</h3>
           <Button id="10" onClick={this.afficherQuestions}>300</Button><Button id="11" onClick={this.afficherQuestions}>600</Button><Button id="12" onClick={this.afficherQuestions}>1200</Button><Button id="13" onClick={this.afficherQuestions}>2400</Button>  <Button id="14" onClick={this.afficherQuestions}>3600</Button>
           <h3>divertissement</h3>
           <Button id="15" onClick={this.afficherQuestions}>300</Button><Button id="16" onClick={this.afficherQuestions}>600</Button><Button id="17" onClick={this.afficherQuestions}>1200</Button><Button id="18" onClick={this.afficherQuestions}>2400</Button>  <Button id="19" onClick={this.afficherQuestions}>3600</Button>
           <h3>loisir/tech</h3>
           <Button id="20" onClick={this.afficherQuestions}>300</Button><Button id="21" onClick={this.afficherQuestions}>600</Button><Button id="22" onClick={this.afficherQuestions}>1200</Button><Button id="23" onClick={this.afficherQuestions}>2400</Button>  <Button id="24" onClick={this.afficherQuestions}>3600</Button>
            <div>{this.state.listeQuestions.map((question, i) => <div key={i}><React.Fragment>  <div className="moyenneFont" dangerouslySetInnerHTML={{ __html: question.question }}></div> 
                                {  question.reponses.map((reponse, j) =><div id={i*100+j} key={i*100+j} 
                dangerouslySetInnerHTML={{ __html: reponse }}></div>)}
                                <div>Bonne Réponses : {question.bonneReponse}</div>
                            </React.Fragment></div> )}</div>
       </div>
       </div>



    }


}