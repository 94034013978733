import React from 'react';
import { Link} from 'react-router-dom';




function creerLiens(type)
{
    type = type.replace('ns', '');
    let tabLien=[
        {type:'histoire', lien: '/juste-nombre/histoire', texte: 'Les énigmes des dates historiques'},
        {type:'geographie', lien: '/juste-nombre/geographie', texte: 'Les énigmes de la géographie'},
        {type:'divers', lien: '/juste-nombre/divers', texte: 'Trouver les prix des divertissements'},
        {type:'mode', lien: '/juste-nombre/mode', texte: 'Les énigmes de la mode féminine'}
    ];
    let index = tabLien.findIndex(info => info.type === type);
    return <div className="centre">
   
      <div><div className="margeHaut10 moyenneFont"><Link to={tabLien[index]}>Refaire le même type de test</Link></div></div>
  <div>{tabLien.map((info, i) => info.type !== type && <div className="margeHaut10 moyenneFont" key={i}><Link to={info.lien}>{info.texte}</Link></div> )}</div>
  <div className="margeHaut10 moyenneFont"><Link to="/">Retour à la page d'accueil</Link></div>

    </div>
}

export {creerLiens};