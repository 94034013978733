const dataOrdre = [{
    titre: "L'histoire de France",
    categorie : 1,
    type : 1,
    id : 0,
    commentaire: true,
    jeu: [

        {
            "titre": "Bataille de Poitiers",
            "info1": "25/10/732",
            "info2": "",
            "commentaire": "Charles Martel arrête l'invasion des forces musulmanes omeyyades, marquant un tournant dans l'histoire de l'Europe."
        },
        {
            "titre": "Couronnement de Charlemagne",
            "info1": "25/12/800",
            "info2": "",
            "commentaire": "Charlemagne est couronné empereur d'Occident par le pape Léon III, marquant le début du Saint-Empire romain germanique."
        },
        {
            "titre": "Bataille de Hastings",
            "info1": "14/10/1066",
            "info2": "",
            "commentaire": "Guillaume le Conquérant, duc de Normandie, envahit l'Angleterre et devient roi, changeant la face de l'histoire anglaise et française."
        },
        {
            "titre": "Traité de Verdun",
            "info1": "10/08/843",
            "info2": "",
            "commentaire": "Le traité divise l'Empire carolingien en trois royaumes, posant les bases des futures nations de France et d'Allemagne."
        },
        {
            "titre": "Bataille de Bouvines",
            "info1": "27/07/1214",
            "info2": "",
            "commentaire": "Philippe II Auguste remporte une victoire décisive contre une coalition de seigneurs européens, renforçant la monarchie française."
        },
        {
            "titre": "Guerre de Cent Ans",
            "info1": "1337",
            "info2": "1453",
            "commentaire": "Une série de conflits entre la France et l'Angleterre sur des revendications territoriales et successorales, se terminant par la victoire française."
        },
        {
            "titre": "Prise de la Bastille",
            "info1": "14/07/1789",
            "info2": "",
            "commentaire": "La prise de la Bastille marque le début de la Révolution française et devient un symbole de la lutte contre la tyrannie."
        },
        {
            "titre": "Proclamation de la République",
            "info1": "21/09/1792",
            "info2": "",
            "commentaire": "La Première République française est proclamée, abolissant la monarchie."
        },
        {
            "titre": "Napoléon devient empereur",
            "info1": "02/12/1804",
            "info2": "",
            "commentaire": "Napoléon Bonaparte se couronne empereur des Français, établissant le Premier Empire."
        },
        {
            "titre": "Bataille de Waterloo",
            "info1": "18/06/1815",
            "info2": "",
            "commentaire": "La défaite de Napoléon à Waterloo marque la fin de son règne et de son retour au pouvoir durant les Cent-Jours."
        },
        {
            "titre": "Révolution de Juillet",
            "info1": "27/07/1830",
            "info2": "29/07/1830",
            "commentaire": "La révolution conduit à l'abdication de Charles X et à l'instauration de la monarchie de Juillet sous Louis-Philippe."
        },
        {
            "titre": "Proclamation de la Troisième République",
            "info1": "04/09/1870",
            "info2": "",
            "commentaire": "Après la défaite de Napoléon III à Sedan, la Troisième République est proclamée en France."
        },
        {
            "titre": "Signature du traité de Versailles",
            "info1": "28/06/1919",
            "info2": "",
            "commentaire": "Le traité de Versailles met fin officiellement à la Première Guerre mondiale et impose des réparations lourdes à l'Allemagne."
        },
        {
            "titre": "Appel du 18 Juin",
            "info1": "18/06/1940",
            "info2": "",
            "commentaire": "Le général Charles de Gaulle appelle à la résistance contre l'occupation allemande, marquant le début de la France libre."
        },
        {
            "titre": "Édit de Nantes",
            "info1": "13/04/1598",
            "info2": "",
            "commentaire": "Henri IV signe l'Édit de Nantes, accordant des droits religieux aux protestants et mettant fin aux guerres de religion en France."
        },
        {
            "titre": "Règne de Louis XIV",
            "info1": "14/05/1643",
            "info2": "01/09/1715",
            "commentaire": "Le règne du Roi-Soleil, caractérisé par une centralisation du pouvoir royal et un renforcement de l'absolutisme."
        },
        {
            "titre": "Révolution de 1848",
            "info1": "22/02/1848",
            "info2": "02/12/1852",
            "commentaire": "Cette révolution mène à l'abolition de la monarchie de Juillet et à la proclamation de la Deuxième République."
        },
        {
            "titre": "Proclamation de la 2eme République",
            "info1": "24/02/1848",
            "info2": "",
            "commentaire": "La Deuxième République est proclamée après l'abdication de Louis-Philippe, marquant une période de changements politiques majeurs."
        },
        {
            "titre": "Commune de Paris",
            "info1": "18/03/1871",
            "info2": "28/05/1871",
            "commentaire": "Un gouvernement révolutionnaire est établi à Paris suite à la défaite de la France dans la guerre franco-prussienne."
        },
        {
            "titre": "Exposition Universelle de Paris",
            "info1": "06/05/1889",
            "info2": "31/10/1889",
            "commentaire": "L'Exposition universelle  célèbre le centenaire de la Révolution française et voit l'inauguration de la Tour Eiffel."
        },
        {
            "titre": "Front populaire",
            "info1": "03/05/1936",
            "info2": "10/04/1938",
            "commentaire": "Le gouvernement du Front populaire, dirigé par Léon Blum, met en œuvre des réformes sociales importantes comme les congés payés et la semaine de 40 heures."
        },
        {
            "titre": "Bataille de Verdun",
            "info1": "21/02/1916",
            "info2": "18/12/1916",
            "commentaire": "Une des batailles les plus longues et les plus dévastatrices de la Première Guerre mondiale, symbolisant la ténacité des forces françaises."
        },
        {
            "titre": "Accords d'Évian",
            "info1": "18/03/1962",
            "info2": "",
            "commentaire": "Les accords mettent fin à la guerre d'Algérie et ouvrent la voie à l'indépendance de l'Algérie."
        },
        {
            "titre": "Mai 68",
            "info1": "03/05/1968",
            "info2": "30/06/1968",
            "commentaire": "Une période de manifestations et de grèves qui secoue la France, entraînant des changements sociaux et politiques importants."
        },
        {
            "titre": "Passage à l'euro",
            "info1": "01/01/2002",
            "info2": "",
            "commentaire": "La France adopte l'euro comme monnaie officielle, remplaçant le franc."
        },
        {
            "titre": "Attentats de Paris",
            "info1": "13/11/2015",
            "info2": "",
            "commentaire": "Une série d'attaques terroristes coordonnées frappent Paris, causant la mort de 130 personnes et conduisant à une intensification des mesures de sécurité."
        },
        {
            "titre": "Incendie de Notre-Dame de Paris",
            "info1": "15/04/2019",
            "info2": "",
            "commentaire": "Un incendie dévaste la cathédrale Notre-Dame de Paris, entraînant un élan national et international pour sa reconstruction."
        }

    ]

},
{
    titre: "Les événements de la Première Guerre mondiale",
    categorie : 1,
    type : 1,
    id : 1,
    commentaire: false,
    jeu: [

        {
            titre: "Assassinat de l'archiduc François-Ferdinand",
            info1: "28/06/1914",
            info2: "",
            commentaire: "L'assassinat de l'archiduc François-Ferdinand d'Autriche-Hongrie à Sarajevo par un nationaliste serbe a déclenché une série d'événements menant à la Première Guerre mondiale."
        },
        {
            titre: "Déclaration de guerre de l'Autriche à la Serbie",
            info1: "28/07/1914",
            info2: "",
            commentaire: "Suite à l'assassinat de l'archiduc, l'Autriche-Hongrie a déclaré la guerre à la Serbie, déclenchant un effet domino impliquant les alliances européennes."
        },
        {
            titre: "Bataille de la Marne",
            info1: "6/9/1914",
            info2: "12/9/1914",
            commentaire: "Cette bataille décisive a stoppé l'avancée allemande en France et a marqué le début de la guerre de tranchées sur le front occidental."
        },

        {
            titre: "Bataille de Verdun",
            info1: "21/2/1916",
            info2: "18/12/1916",
            commentaire: "L'une des plus longues et des plus meurtrières batailles de la guerre, avec de lourdes pertes pour les armées française et allemande."
        },
        {
            titre: "Bataille de la Somme",
            info1: "1/7/1916",
            info2: "18/9/1916",
            commentaire: "Grande offensive alliée sur le front occidental, marquée par des pertes massives et l'utilisation des tanks pour la première fois."
        },
        {
            titre: "Entrée en guerre des États-Unis",
            info1: "6/4/1917",
            info2: "",
            commentaire: "Les États-Unis ont rejoint les Alliés après une série de provocations allemandes, y compris la guerre sous-marine sans restriction et le télégramme Zimmermann."
        },

        {
            titre: "Révolution russe d'Octobre",
            info1: "7/11/1917",
            info2: "8/11/1917",
            commentaire: "La Révolution d'Octobre a permis aux bolcheviks de prendre le pouvoir en Russie, conduisant à la création de l'Union soviétique."
        },
        {
            titre: "Armistice de Compiègne",
            info1: "11/11/1918",
            info2: "",
            commentaire: "L'armistice signé entre les Alliés et l'Allemagne a mis fin aux combats sur le front occidental, marquant la fin de la Première Guerre mondiale."
        },
        {
            titre: "Traité de Versailles",
            info1: "28/6/1919",
            info2: "",
            commentaire: "Ce traité a officiellement mis fin à la guerre, imposant de lourdes réparations à l'Allemagne et redessinant les frontières en Europe."
        },
        {
            titre: "Offensive du Printemps (Kaiserschlacht)",
            info1: "21/3/1918",
            info2: "18/7/1918",
            commentaire: "Dernière grande offensive allemande sur le front occidental visant à briser les lignes alliées avant l'arrivée massive des troupes américaines."
        }


    ]
},

{
    titre: "Les Rois de France (très difficile)",
    categorie : 1,
    type : 1,
    id : 2,
    commentaire: true,
    jeu: [
        {
            titre: "Charlemagne",
            info1: "768",
            info2: "814",
            commentaire: "Empereur des Francs et fondateur de l'Empire carolingien. Il a unifié une grande partie de l'Europe occidentale et encouragé la renaissance carolingienne, une période de renouveau culturel et intellectuel."
        },
        {
            titre: "Hugues Capet",
            info1: "987",
            info2: "996",
            commentaire: "Fondateur de la dynastie capétienne qui a régné sur la France pendant plus de 800 ans. Son élection marque le début de la stabilisation du royaume après les troubles des siècles précédents."
        },
        {
            titre: "Philippe II Auguste",
            info1: "1180",
            info2: "1223",
            commentaire: "Roi de France qui a considérablement augmenté le territoire français et renforcé le pouvoir royal. Il a participé à la troisième croisade et modernisé l'administration du royaume."
        },
        {
            titre: "Louis IX (Saint Louis)",
            info1: "1226",
            info2: "1270",
            commentaire: "Roi pieux et juste, il a participé à deux croisades et a été canonisé après sa mort. Louis IX est connu pour son intégrité et son sens de la justice, ainsi que pour avoir édifié la Sainte-Chapelle à Paris."
        },
        {
            titre: "François Ier",
            info1: "1515",
            info2: "1547",
            commentaire: "Roi de la Renaissance française, mécène des arts et des lettres, il a encouragé les grandes découvertes et développé le château de Chambord. Son règne est marqué par les guerres d'Italie et les conflits avec Charles Quint."
        },
        {
            titre: "Henri IV",
            info1: "1589",
            info2: "1610",
            commentaire: "Premier roi de la branche bourbon, il a rétabli la paix religieuse en France avec l'Édit de Nantes. Il est célèbre pour sa phrase 'Paris vaut bien une messe' et pour ses efforts en faveur de la réconciliation nationale et de la reconstruction du pays après les guerres de religion."
        },
        {
            titre: "Louis XIV",
            info1: "1643",
            info2: "1715",
            commentaire: "Le Roi Soleil, symbole de l'absolutisme, il a construit le château de Versailles et centralisé le pouvoir en France. Son règne est le plus long de l'histoire française, et il a fait de la France la principale puissance européenne tout en soutenant les arts et la culture."
        },
        {
            titre: "Clovis I",
            info1: "481",
            info2: "511",
            commentaire: "Premier roi des Francs à unir toutes les tribus franques sous une seule bannière. Converti au christianisme, il a jeté les bases de l'alliance entre la monarchie franque et l'Église catholique."
        },

        {
            titre: "Louis VIII",
            info1: "1223",
            info2: "1226",
            commentaire: "Roi de France connu pour sa brève mais efficace campagne contre les Cathares dans le sud de la France et pour avoir posé les bases de l'annexion de ces territoires au royaume."
        },
        {
            titre: "Philippe IV le Bel",
            info1: "1285",
            info2: "1314",
            commentaire: "Roi de France connu pour ses réformes administratives et fiscales. Il est également célèbre pour son conflit avec le pape Boniface VIII et la suppression de l'ordre des Templiers."
        },
        {
            titre: "Charles V",
            info1: "1364",
            info2: "1380",
            commentaire: "Surnommé 'Charles le Sage', il a restauré l'ordre après les crises de la guerre de Cent Ans et les épidémies de peste noire, et a renforcé l'administration royale."
        },
        {
            titre: "Charles VII",
            info1: "1422",
            info2: "1461",
            commentaire: "Avec l'aide de Jeanne d'Arc, il a reconquis de nombreux territoires occupés par les Anglais pendant la guerre de Cent Ans et a été couronné à Reims, restaurant ainsi la légitimité de la monarchie française."
        },
        {
            titre: "Louis XI",
            info1: "1461",
            info2: "1483",
            commentaire: "Surnommé 'l'Universel Aragne' (l'universelle araignée) pour son habileté politique et diplomatique. Il a renforcé le pouvoir royal et affaibli les grands féodaux, on dit qu'il enfermait ses ennemis dans de minuscules cages."
        },
        {
            titre: "Henri II",
            info1: "1547",
            info2: "1559",
            commentaire: "Fils de François Ier, il a poursuivi les guerres contre l'Empire et l'Angleterre et a consolidé le pouvoir royal. Sa mort accidentelle lors d'un tournoi a marqué la fin de la Renaissance française."
        },
        {
            titre: "Charles IX",
            info1: "1560",
            info2: "1574",
            commentaire: "Roi pendant les guerres de religion, son règne est surtout marqué par le massacre de la Saint-Barthélemy en 1572, où des milliers de protestants huguenots ont été tués."
        },
        {
            titre: "Louis XIII",
            info1: "1610",
            info2: "1643",
            commentaire: "Avec l'aide de son ministre, le cardinal Richelieu, il a renforcé l'autorité royale et posé les bases de l'absolutisme que son fils, Louis XIV, allait pleinement développer."
        }

    ]
},
{
    titre: "Les événements de la Seconde Guerre mondiale",
    categorie : 1,
    type : 1,
    id : 3,
    commentaire: true,
    jeu: [

        {
            "titre": "Invasion de la Pologne",
            "info1": "01/09/1939",
            "info2": "",
            "commentaire": "Le début de la Seconde Guerre mondiale, marqué par l'invasion de la Pologne par l'Allemagne nazie."
        },
        {
            "titre": "Bataille de France",
            "info1": "10/05/1940",
            "info2": "25/06/1940",
            "commentaire": "L'invasion rapide de la France par les forces allemandes, menant à l'occupation de Paris."
        },
        {
            "titre": "Bataille d'Angleterre",
            "info1": "10/07/1940",
            "info2": "31/10/1940",
            "commentaire": "Une campagne aérienne menée par la Luftwaffe contre le Royaume-Uni, visant à obtenir la supériorité aérienne."
        },
        {
            "titre": "Opération Barbarossa",
            "info1": "22/06/1941",
            "info2": "",
            "commentaire": "L'invasion de l'Union soviétique par l'Allemagne nazie, marquant le début d'un des plus grands fronts de la guerre."
        },
        {
            "titre": "Attaque de Pearl Harbor",
            "info1": "07/12/1941",
            "info2": "",
            "commentaire": "L'attaque surprise de la marine japonaise sur la base navale américaine de Pearl Harbor, entraînant l'entrée des États-Unis dans la guerre."
        },
        {
            "titre": "Débarquement de Normandie",
            "info1": "06/06/1944",
            "info2": "",
            "commentaire": "Le début de l'opération Overlord avec le débarquement des forces alliées en Normandie, marquant le début de la libération de l'Europe de l'Ouest."
        },
        {
            "titre": "Bataille de Stalingrad",
            "info1": "17/07/1942",
            "info2": "02/02/1943",
            "commentaire": "Une des batailles les plus sanglantes de l'histoire, marquant un tournant décisif en faveur des Alliés sur le front de l'Est."
        },
        {
            "titre": "Capitulation de l'Allemagne",
            "info1": "08/05/1945",
            "info2": "",
            "commentaire": "La fin de la Seconde Guerre mondiale en Europe, marquée par la reddition inconditionnelle de l'Allemagne nazie."
        },
        {
            "titre": "Bombardements d'Hiroshima et Nagasaki",
            "info1": "06/08/1945",
            "info2": "09/08/1945",
            "commentaire": "Les États-Unis larguent des bombes atomiques sur les villes japonaises d'Hiroshima et Nagasaki, menant à la capitulation du Japon."
        },
        {
            "titre": "Capitulation du Japon",
            "info1": "02/09/1945",
            "info2": "",
            "commentaire": "La fin officielle de la Seconde Guerre mondiale avec la signature de l'acte de reddition par le Japon."
        },
        {
            "titre": "Conférence de Yalta",
            "info1": "04/02/1945",
            "info2": "11/02/1945",
            "commentaire": "Rencontre entre les dirigeants alliés pour planifier la fin de la guerre et l'ordre mondial post-guerre."
        },
        {
            "titre": "Conférence de Potsdam",
            "info1": "17/07/1945",
            "info2": "02/08/1945",
            "commentaire": "Dernière conférence des alliés avant la fin de la guerre, où furent discutés les termes de la reddition du Japon et les arrangements d'après-guerre en Europe."
        },
        {
            "titre": "Bataille de Midway",
            "info1": "04/06/1942",
            "info2": "07/06/1942",
            "commentaire": "Une bataille décisive dans le Pacifique, où la flotte américaine infligea une défaite majeure à la marine japonaise."
        }

    ]
},
{
    titre: "La période napoléonienne (difficile)",
    categorie : 1,
    type : 1,
    id : 4,
    commentaire: true,
    jeu: [
        {
            "titre": "Coup d'État du 18 Brumaire",
            "info1": "09/11/1799",
            "info2": "",
            "commentaire": "Napoléon Bonaparte prend le pouvoir en France par un coup d'État, marquant la fin du Directoire et le début du Consulat."
        },
        {
            "titre": "Proclamation de Napoléon comme Empereur",
            "info1": "18/05/1804",
            "info2": "",
            "commentaire": "Napoléon Bonaparte est proclamé Empereur des Français, établissant le Premier Empire."
        },
        {
            "titre": "Bataille d'Austerlitz",
            "info1": "02/12/1805",
            "info2": "",
            "commentaire": "L'une des plus grandes victoires de Napoléon, où il bat les forces austro-russes, consolidant sa domination sur l'Europe."
        },
        {
            "titre": "Bataille de Trafalgar",
            "info1": "21/10/1805",
            "info2": "",
            "commentaire": "Une défaite navale majeure pour Napoléon face à la flotte britannique dirigée par l'amiral Nelson, assurant la suprématie maritime britannique."
        },
        {
            "titre": "Campagne de Russie",
            "info1": "24/06/1812",
            "info2": "14/12/1812",
            "commentaire": "L'invasion désastreuse de la Russie par Napoléon, marquée par de lourdes pertes et une retraite difficile."
        },
        {
            "titre": "Bataille de Leipzig",
            "info1": "16/10/1813",
            "info2": "19/10/1813",
            "commentaire": "Également connue sous le nom de Bataille des Nations, cette défaite pour Napoléon marque le début de la fin de son règne."
        },
        {
            "titre": "Première abdication de Napoléon",
            "info1": "06/04/1814",
            "info2": "",
            "commentaire": "Napoléon abdique pour la première fois et est exilé sur l'île d'Elbe."
        },
        {
            "titre": "Les Cent-Jours",
            "info1": "20/03/1815",
            "info2": "08/07/1815",
            "commentaire": "Période durant laquelle Napoléon revient au pouvoir en France après s'être échappé de l'île d'Elbe, avant d'être finalement vaincu à Waterloo."
        },
        {
            "titre": "Bataille de Waterloo",
            "info1": "18/06/1815",
            "info2": "",
            "commentaire": "La défaite décisive de Napoléon par les forces britanniques et prussiennes, menant à sa seconde abdication."
        },
        {
            "titre": "Exil à Sainte-Hélène",
            "info1": "15/10/1815",
            "info2": "",
            "commentaire": "Après sa défaite à Waterloo, Napoléon est exilé sur l'île de Sainte-Hélène où il passera le reste de sa vie."
        },
        {
            "titre": "Code civil Napoléonien",
            "info1": "21/03/1804",
            "info2": "",
            "commentaire": "Également connu sous le nom de Code Napoléon, ce code civil est l'une des réformes législatives les plus durables de Napoléon, influençant de nombreux systèmes juridiques à travers le monde."
        },
        {
            "titre": "Blocus continental",
            "info1": "21/11/1806",
            "info2": "11/4/1814",
            "commentaire": "Décrété par Napoléon pour affaiblir l'économie britannique en interdisant le commerce entre les nations européennes et le Royaume-Uni."
        },
        {
            "titre": "Bataille de Borodino",
            "info1": "07/09/1812",
            "info2": "",
            "commentaire": "Une des batailles les plus sanglantes de la campagne de Russie, sans victoire décisive mais marquant la résistance russe."
        }
    ]
},
{
    titre: "Les présidents de la 5e République",
    categorie : 1,
    type : 1,
    id : 5,
    commentaire: true,
    jeu: [
        {
            "titre": "Charles de Gaulle",
            "info1": "08/01/1959",
            "info2": "28/04/1969",
            "commentaire": "Premier président de la Cinquième République, instigateur de la nouvelle constitution, et leader de la France libre durant la Seconde Guerre mondiale."
        },
        {
            "titre": "Georges Pompidou",
            "info1": "20/06/1969",
            "info2": "02/04/1974",
            "commentaire": "Ancien Premier ministre sous de Gaulle, il poursuivit la modernisation de la France et mourut en fonction."
        },
        {
            "titre": "Valéry Giscard d'Estaing",
            "info1": "27/05/1974",
            "info2": "21/05/1981",
            "commentaire": "Élu à 48 ans, il initia de nombreuses réformes sociales et économiques, et promut l'intégration européenne."
        },
        {
            "titre": "François Mitterrand",
            "info1": "21/05/1981",
            "info2": "17/05/1995",
            "commentaire": "Premier président socialiste, il a effectué deux mandats, marqués par des réformes importantes et une cohabitation avec la droite."
        },
        {
            "titre": "Jacques Chirac",
            "info1": "17/05/1995",
            "info2": "16/05/2007",
            "commentaire": "Il a effectué deux mandats, mettant en œuvre des réformes économiques et sociales, et s'opposant à l'invasion de l'Irak en 2003."
        },
        {
            "titre": "Nicolas Sarkozy",
            "info1": "16/05/2007",
            "info2": "15/05/2012",
            "commentaire": "Son mandat a été marqué par des réformes économiques et sociales, ainsi que par la crise financière mondiale de 2008."
        },
        {
            "titre": "François Hollande",
            "info1": "15/05/2012",
            "info2": "14/05/2017",
            "commentaire": "Président socialiste, son mandat a été marqué par des attentats terroristes et des réformes économiques contestées."
        },
        {
            "titre": "Emmanuel Macron",
            "info1": "14/05/2017",
            "info2": "",
            "commentaire": "Le plus jeune président de la Cinquième République, son mandat est marqué par des réformes économiques, les Gilets jaunes et la gestion de la pandémie de Covid-19."
        }
    ]

},
{
    titre: "La science et les grandes découvertes",
    categorie : 1,
    type : 1,
    id : 6,
    commentaire: true,
    jeu: [
        {
            "titre": "Découverte de l'Amérique par Christophe Colomb",
            "info1": "12/10/1492",
            "info2": "",
            "commentaire": "Christophe Colomb atteint les Amériques, ouvrant une nouvelle ère d'exploration et de colonisation européennes."
        },
        {
            "titre": "L'héliocentrisme de Copernic",
            "info1": "1543",
            "info2": "",
            "commentaire": "Nicolas Copernic publie 'De revolutionibus orbium coelestium', proposant que la Terre et les autres planètes tournent autour du Soleil."
        },
        {
            "titre": "Les travaux de Galilée",
            "info1": "1609",
            "info2": "1610",
            "commentaire": "Galilée améliore le télescope et découvre les lunes de Jupiter, apportant des preuves en faveur de l'héliocentrisme."
        },
        {
            "titre": "Découverte de la gravitation universelle",
            "info1": "1687",
            "info2": "",
            "commentaire": "Isaac Newton publie 'Philosophiæ Naturalis Principia Mathematica', formulant la loi de la gravitation universelle et les lois du mouvement."
        },
        {
            "titre": "Théorie de l'évolution de Darwin",
            "info1": "24/11/1859",
            "info2": "",
            "commentaire": "Charles Darwin publie 'De l'origine des espèces', présentant la théorie de l'évolution par sélection naturelle."
        },
        {
            "titre": "Découverte des rayons X",
            "info1": "08/11/1895",
            "info2": "",
            "commentaire": "Wilhelm Röntgen découvre les rayons X, révolutionnant la médecine et l'imagerie médicale."
        },
        {
            "titre": "Théorie de la relativité",
            "info1": "1905",
            "info2": "1915",
            "commentaire": "Albert Einstein développe la théorie de la relativité restreinte et générale, transformant notre compréhension de l'espace, du temps et de la gravité."
        },
        {
            "titre": "Découverte de la structure de l'ADN",
            "info1": "25/04/1953",
            "info2": "",
            "commentaire": "James Watson et Francis Crick publient leur modèle de la double hélice de l'ADN, révélant le mécanisme de la transmission génétique."
        },
        {
            "titre": "Premier homme sur la Lune",
            "info1": "20/07/1969",
            "info2": "",
            "commentaire": "Neil Armstrong et Buzz Aldrin deviennent les premiers humains à marcher sur la Lune lors de la mission Apollo 11."
        },
        {
            "titre": "Découverte du boson de Higgs",
            "info1": "04/07/2012",
            "info2": "",
            "commentaire": "Les scientifiques du CERN annoncent la découverte du boson de Higgs, confirmant une pièce clé du modèle standard de la physique des particules."
        },
        {
            "titre": "Séquençage complet du génome humain",
            "info1": "14/04/2003",
            "info2": "",
            "commentaire": "Le projet du génome humain annonce avoir séquencé l'intégralité de l'ADN humain, ouvrant de nouvelles perspectives en médecine et en biologie."
        },
        {
            "titre": "Invention du microscope",
            "info1": "1590",
            "info2": "",
            "commentaire": "Hans et Zacharias Janssen inventent le premier microscope composé, permettant des avancées considérables en biologie."
        },
        {
            "titre": "Découverte de l'oxygène",
            "info1": "1774",
            "info2": "",
            "commentaire": "Joseph Priestley et Carl Wilhelm Scheele découvrent indépendamment l'oxygène, une avancée majeure en chimie."
        },
        {
            "titre": "Invention de la machine à vapeur",
            "info1": "1769",
            "info2": "",
            "commentaire": "James Watt améliore la machine à vapeur de Thomas Newcomen, ouvrant la voie à la révolution industrielle."
        },
        {
            "titre": "Découverte des électrons",
            "info1": "1897",
            "info2": "",
            "commentaire": "J.J. Thomson découvre l'électron, révolutionnant notre compréhension de la structure atomique."
        },
        {
            "titre": "Découverte de la pénicilline",
            "info1": "1928",
            "info2": "",
            "commentaire": "Alexander Fleming découvre la pénicilline, le premier antibiotique, révolutionnant la médecine moderne."
        },
        {
            "titre": "Projet Manhattan",
            "info1": "1942",
            "info2": "1945",
            "commentaire": "Les États-Unis développent la première bombe atomique, transformant la guerre et la géopolitique mondiale."
        },
        {
            "titre": "Lancement du satellite Spoutnik",
            "info1": "04/10/1957",
            "info2": "",
            "commentaire": "L'Union soviétique lance Spoutnik, le premier satellite artificiel, marquant le début de l'ère spatiale."
        },
        {
            "titre": "Découverte du VIH",
            "info1": "1983",
            "info2": "",
            "commentaire": "Françoise Barré-Sinoussi et Luc Montagnier identifient le virus de l'immunodéficience humaine (VIH), une avancée cruciale dans la lutte contre le SIDA."
        },
        {
            "titre": "Premier clone de mammifère",
            "info1": "05/07/1996",
            "info2": "",
            "commentaire": "Dolly, la brebis, devient le premier mammifère cloné à partir d'une cellule adulte, ouvrant de nouvelles perspectives en biotechnologie."
        },
        {
            "titre": "Premier vol spatial habité",
            "info1": "12/04/1961",
            "info2": "",
            "commentaire": "Youri Gagarine devient le premier homme à voyager dans l'espace à bord de Vostok 1, marquant un jalon dans l'exploration spatiale."
        }
    ]

},
{
    titre: "L'histoire des États-Unis",
    categorie : 1,
    type : 1,
    id : 7,
    commentaire: true,
    jeu: [
        {
            "titre": "Déclaration d'indépendance",
            "info1": "04/07/1776",
            "info2": "",
            "commentaire": "Les treize colonies américaines déclarent leur indépendance de la Grande-Bretagne, marquant la naissance des États-Unis."
        },
        {
            "titre": "Constitution des États-Unis",
            "info1": "17/09/1787",
            "info2": "",
            "commentaire": "La Constitution est adoptée par la Convention constitutionnelle, établissant le cadre du gouvernement fédéral des États-Unis."
        },
        {
            "titre": "Guerre de Sécession",
            "info1": "12/04/1861",
            "info2": "09/04/1865",
            "commentaire": "Un conflit entre les États du Nord et les États du Sud sur des questions de droits des États et de l'esclavage, qui se termine par la victoire de l'Union."
        },
        {
            "titre": "Abolition de l'esclavage",
            "info1": "01/01/1863",
            "info2": "",
            "commentaire": "La Proclamation d'émancipation de Lincoln déclare la liberté de tous les esclaves dans les États confédérés en guerre contre l'Union."
        },
        {
            "titre": "Assassinat de Lincoln",
            "info1": "14/04/1865",
            "info2": "",
            "commentaire": "Le président Lincoln est assassiné par John Wilkes Booth, marquant un tournant dramatique dans l'histoire américaine."
        },
        {
            "titre": "Adoption du XIXe amendement",
            "info1": "18/08/1920",
            "info2": "",
            "commentaire": "Le XIXe amendement à la Constitution accorde le droit de vote aux femmes, marquant une étape importante dans le mouvement pour les droits des femmes."
        },
        {
            "titre": "Crise de 1929",
            "info1": "29/10/1929",
            "info2": "",
            "commentaire": "Le krach boursier de Wall Street marque le début de la Grande Dépression, une période de difficultés économiques mondiales."
        },
        {
            "titre": "Seconde Guerre mondiale",
            "info1": "07/12/1941",
            "info2": "02/09/1945",
            "commentaire": "Les États-Unis entrent en guerre après l'attaque de Pearl Harbor et jouent un rôle crucial dans la défaite des puissances de l'Axe."
        },
        {
            "titre": "Loi sur les droits civiques",
            "info1": "02/07/1964",
            "info2": "",
            "commentaire": "Le président Lyndon B. Johnson signe la loi sur les droits civiques, interdisant la discrimination fondée sur la race, la couleur, la religion, le sexe ou l'origine nationale."
        },
        {
            "titre": "Premier homme sur la Lune",
            "info1": "20/07/1969",
            "info2": "",
            "commentaire": "Les astronautes américains Neil Armstrong et Buzz Aldrin marchent sur la Lune lors de la mission Apollo 11."
        },
        {
            "titre": "Attentats du 11 septembre",
            "info1": "11/09/2001",
            "info2": "",
            "commentaire": "Des attaques terroristes coordonnées par Al-Qaïda frappent les États-Unis, entraînant la guerre contre le terrorisme."
        },
        {
            "titre": "Élection de Barack Obama",
            "info1": "04/11/2008",
            "info2": "",
            "commentaire": "Barack Obama est élu 44e président des États-Unis, devenant le premier président afro-américain du pays."
        },
        {
            "titre": "Débarquement des Pèlerins",
            "info1": "21/11/1620",
            "info2": "",
            "commentaire": "Les Pèlerins du Mayflower débarquent à Plymouth Rock, établissant une des premières colonies européennes permanentes en Amérique du Nord."
        },
        {
            "titre": "Achat de la Louisiane",
            "info1": "30/04/1803",
            "info2": "",
            "commentaire": "Les États-Unis achètent la Louisiane à la France, doublant ainsi la superficie du pays."
        },
        {
            "titre": "Traité de Guadalupe Hidalgo",
            "info1": "02/02/1848",
            "info2": "",
            "commentaire": "Le traité met fin à la guerre américano-mexicaine et cède aux États-Unis une vaste portion de territoire incluant la Californie et le Nouveau-Mexique."
        },
        {
            "titre": "Abolition de l'esclavage",
            "info1": "06/12/1865",
            "info2": "",
            "commentaire": "Le XIIIe amendement à la Constitution des États-Unis est ratifié, abolissant officiellement l'esclavage dans tout le pays."
        },
        {
            "titre": "Mouvement des droits civiques",
            "info1": "1954",
            "info2": "1968",
            "commentaire": "Un mouvement de lutte pour l'égalité des droits civiques et la fin de la ségrégation raciale, marqué par des figures telles que Martin Luther King Jr."
        },
        {
            "titre": "Scandale du Watergate",
            "info1": "17/06/1972",
            "info2": "09/08/1974",
            "commentaire": "Un scandale politique qui mène à la démission du président Richard Nixon."
        },
        {
            "titre": "Attentat d'Oklahoma City",
            "info1": "19/04/1995",
            "info2": "",
            "commentaire": "Une attaque terroriste domestique qui cause la mort de 168 personnes, marquant un tournant dans la lutte contre le terrorisme intérieur."
        }
    ]

},
{
    titre: "Les droits des femmes en France",
    categorie : 1,
    type : 1,
    id : 8,
    commentaire: true,
    jeu:
        [
            {
                "titre": "Droit de vote des femmes",
                "info1": "21/04/1944",
                "info2": "",
                "commentaire": "Les femmes obtiennent le droit de vote et d'éligibilité en France grâce à une ordonnance du Comité français de la Libération nationale."
            },

            {
                "titre": "Droit d'avoir un compte en banque",
                "info1": "13/07/1965",
                "info2": "",
                "commentaire": "Les femmes obtiennent le droit d'ouvrir un compte en banque en leur propre nom et de disposer de leurs biens."
            },
            {
                "titre": "Loi Neuwirth",
                "info1": "28/12/1967",
                "info2": "",
                "commentaire": "La loi Neuwirth autorise la contraception en France, marquant un tournant dans les droits reproductifs des femmes."
            },
            {
                "titre": "Dépénalisation de l'avortement",
                "info1": "17/01/1975",
                "info2": "",
                "commentaire": "La loi Veil, défendue par Simone Veil, dépénalise l'interruption volontaire de grossesse (IVG) en France."
            },
            {
                "titre": "Égalité professionnelle",
                "info1": "13/07/1983",
                "info2": "",
                "commentaire": "La loi Roudy pour l'égalité professionnelle entre les hommes et les femmes est adoptée, visant à réduire les inégalités sur le lieu de travail."
            },
            {
                "titre": "Parité en politique",
                "info1": "06/06/2000",
                "info2": "",
                "commentaire": "La loi sur la parité impose aux partis politiques de présenter autant de femmes que d'hommes aux élections."
            },
            {
                "titre": "Criminalisation du harcèlement sexuel",
                "info1": "02/07/1992",
                "info2": "",
                "commentaire": "Le harcèlement sexuel est reconnu comme un délit en France et puni par la loi."
            },
            {
                "titre": "Loi contre les violences conjugales",
                "info1": "09/07/2010",
                "info2": "",
                "commentaire": "Une loi renforçant la protection des victimes de violences conjugales est adoptée, incluant le délit de harcèlement au sein du couple et la notion de violence psychologique."
            },
            {
                "titre": "Loi pour l'égalité réelle",
                "info1": "04/08/2014",
                "info2": "",
                "commentaire": "Cette loi vise à promouvoir l'égalité professionnelle et la lutte contre les inégalités dans la vie quotidienne, notamment par des mesures de soutien à la parentalité."
            },
            {
                "titre": "Prolongation du délai légal de l'IVG",
                "info1": "02/03/2022",
                "info2": "",
                "commentaire": "La loi prolonge le délai légal pour recourir à une interruption volontaire de grossesse (IVG) de 12 à 14 semaines de grossesse."
            },

            {
                "titre": "Loi Schiappa contre les violences sexuelles",
                "info1": "03/08/2018",
                "info2": "",
                "commentaire": "Adoption de la loi renforçant la lutte contre les violences sexuelles et sexistes, incluant notamment la lutte contre le harcèlement de rue."
            }
        ]
},

{
    titre: "Les grandes batailles",
    categorie : 1,
    type : 1,
    id : 9,
    commentaire: true,
    jeu:
        [
            {
                "titre": "Bataille de Hastings",
                "info1": "14/10/1066",
                "info2": "",
                "commentaire": "Guillaume le Conquérant bat le roi Harold II d'Angleterre, marquant le début de la conquête normande de l'Angleterre."
            },
            {
                "titre": "Bataille de Bouvines",
                "info1": "27/07/1214",
                "info2": "",
                "commentaire": "Philippe Auguste remporte une victoire décisive contre une coalition de forces dirigée par Jean sans Terre et l'empereur Otton IV, renforçant l'autorité de la monarchie française."
            },
            {
                "titre": "Bataille d'Azincourt",
                "info1": "25/10/1415",
                "info2": "",
                "commentaire": "Henri V d'Angleterre bat les Français malgré une infériorité numérique, marquant un tournant dans la guerre de Cent Ans."
            },

            {
                "titre": "Bataille de Waterloo",
                "info1": "18/06/1815",
                "info2": "",
                "commentaire": "Les forces alliées sous le commandement du duc de Wellington et de Gebhard Leberecht von Blücher défont Napoléon Bonaparte, marquant la fin des guerres napoléoniennes."
            },
            {
                "titre": "Bataille de Gettysburg",
                "info1": "01/07/1863",
                "info2": "03/07/1863",
                "commentaire": "La bataille décisive de la guerre de Sécession américaine où les forces de l'Union repoussent l'armée confédérée du général Lee, marquant un tournant dans la guerre."
            },
            {
                "titre": "Bataille de la Marne",
                "info1": "06/09/1914",
                "info2": "12/09/1914",
                "commentaire": "Les forces alliées franco-britanniques stoppent l'avancée allemande en France, mettant fin à la guerre de mouvement sur le front occidental et débutant la guerre des tranchées."
            },
            {
                "titre": "Bataille de Stalingrad",
                "info1": "17/07/1942",
                "info2": "02/02/1943",
                "commentaire": "L'armée soviétique encercle et détruit la 6e armée allemande, marquant un tournant majeur dans la Seconde Guerre mondiale sur le front de l'Est."
            },
            {
                "titre": "Débarquement de Normandie",
                "info1": "06/06/1944",
                "info2": "",
                "commentaire": "Les forces alliées débarquent en Normandie, marquant le début de la libération de l'Europe occidentale du joug nazi."
            },
            {
                "titre": "Bataille de Dien Bien Phu",
                "info1": "13/03/1954",
                "info2": "07/05/1954",
                "commentaire": "Les forces vietminh battent les troupes françaises, mettant fin à la guerre d'Indochine et conduisant à la fin de la présence coloniale française en Indochine."
            },
            {
                "titre": "Bataille de Trafalgar",
                "info1": "21/10/1805",
                "info2": "",
                "commentaire": "L'amiral Nelson mène la flotte britannique à une victoire décisive contre les flottes française et espagnole, consolidant la domination maritime britannique."
            },
            {
                "titre": "Bataille du Jutland",
                "info1": "31/05/1916",
                "info2": "01/06/1916",
                "commentaire": "La plus grande bataille navale de la Première Guerre mondiale, opposant la Grande-Bretagne à l'Allemagne. Bien qu'indécise, elle confirme la suprématie de la Royal Navy."
            },
            {
                "titre": "Bataille de Midway",
                "info1": "04/06/1942",
                "info2": "07/06/1942",
                "commentaire": "Les États-Unis infligent une défaite décisive à la flotte japonaise, marquant un tournant stratégique dans la guerre du Pacifique."
            },
        ]
},

{
    titre: "Les grands événements du 21e siècle",
    categorie : 1,
    type : 1,
    id : 10,
    commentaire: true,
    jeu: [
        {
            titre: "Attentats du 11 septembre",
            info1: "11/09/2001",
            info2: "",
            commentaire: "Les attaques terroristes coordonnées par Al-Qaïda aux États-Unis ont causé des milliers de morts et ont profondément marqué le début du 21e siècle, entraînant des changements majeurs dans les politiques de sécurité mondiales."
        },
        {
            titre: "Crise financière mondiale",
            info1: "2007",
            info2: "2008",
            commentaire: "La crise financière mondiale a été déclenchée par l'effondrement des marchés financiers et immobiliers, affectant l'économie mondiale et entraînant des récessions dans plusieurs pays."
        },
        {
            titre: "Élection de Barack Obama",
            info1: "04/11/2008",
            info2: "",
            commentaire: "Barack Obama est devenu le premier président afro-américain des États-Unis, marquant un moment historique dans la politique américaine."
        },
        {
            titre: "Révolution du Printemps arabe",
            info1: "2010",
            info2: "2012",
            commentaire: "Série de manifestations et de soulèvements dans plusieurs pays arabes, visant à renverser les régimes autoritaires et demandant des réformes politiques."
        },
        {
            titre: "Pandémie de COVID-19",
            info1: "2020",
            info2: "",
            commentaire: "Une pandémie mondiale causée par le virus SARS-CoV-2, entraînant des millions de décès et des impacts économiques et sociaux significatifs à travers le monde."
        },
        {
            titre: "Catastrophe de Fukushima",
            info1: "11/03/2011",
            info2: "",
            commentaire: "Un séisme et un tsunami ont provoqué des fuites radioactives massives à la centrale nucléaire de Fukushima au Japon, entraînant une crise nucléaire majeure."
        },
        {
            titre: "Référendum sur le Brexit",
            info1: "23/06/2016",
            info2: "",
            commentaire: "Le référendum au Royaume-Uni a conduit à la décision de quitter l'Union européenne, provoquant des changements politiques et économiques significatifs."
        },


        {
            titre: "Révolte des gilets jaunes",
            info1: "17/11/2018",
            info2: "2019",
            commentaire: "Mouvement de protestation en France contre les politiques économiques et sociales, marquant un débat important sur les inégalités et le pouvoir d'achat."
        },
        {
            titre: "Mouvement Black Lives Matter",
            info1: "2013",
            info2: "",
            commentaire: "Mouvement militant international né aux États-Unis, dénonçant la violence et le racisme systémique envers les personnes noires, particulièrement après des incidents de violences policières."
        },
        {
            titre: "L'incendie de Notre-Dame de Paris",
            info1: "15/04/2019",
            info2: "",
            commentaire: "Incendie majeur qui a détruit une partie significative de la cathédrale Notre-Dame de Paris, un symbole historique et culturel."
        },
        {
            titre: "Première élection d'Emmanuel Macron",
            info1: "07/05/2017",
            info2: "",
            commentaire: "Emmanuel Macron devient le plus jeune président de l'histoire française moderne, avec un programme centriste et pro-européen."
        },
        {
            titre: "Attentats du 13 novembre à Paris",
            info1: "13/11/2015",
            info2: "",
            commentaire: "Une série d'attaques terroristes coordonnées à Paris, incluant le Bataclan, causent la mort de 130 personnes, marquant l'une des journées les plus sombres de l'histoire récente de la France."
        },

        {
            titre: "Référendum sur la constitution européenne",
            info1: "29/05/2005",
            info2: "",
            commentaire: "Les Français rejettent la proposition de Constitution européenne lors d'un référendum, un événement marquant dans l'histoire de l'intégration européenne."
        },

    ]
},
{
    titre: "Les grands événements du 20e siècle",
    categorie : 1,
    type : 1,    
    id : 11,
    commentaire: true,
    jeu: [
        {
            titre: "Seconde Guerre mondiale",
            info1: "01/09/1939",
            info2: "02/09/1945",
            commentaire: "Conflit mondial impliquant la plupart des nations, y compris les grandes puissances, divisées en Alliés et Axe. Marqué par des événements tels que l'Holocauste et le bombardement atomique de Hiroshima et Nagasaki."
        },
        {
            titre: "Révolution russe",
            info1: "1917",
            info2: "",
            commentaire: "La révolution bolchevique renverse le tsarisme en Russie, menant à la création de l'Union soviétique et marquant le début d'une ère communiste mondiale."
        },
        {
            titre: "Guerre froide",
            info1: "1947",
            info2: "1991",
            commentaire: "Période de tension géopolitique entre les États-Unis et l'Union soviétique, marquée par la course aux armements nucléaires, la guerre de Corée, la guerre du Vietnam, et la crise des missiles de Cuba."
        },
        {
            titre: "Chute du mur de Berlin",
            info1: "09/11/1989",
            info2: "",
            commentaire: "La chute du mur de Berlin marque la fin de la division de l'Allemagne et symbolise la fin de la Guerre froide et de la domination communiste en Europe de l'Est."
        },
        {
            titre: "Découverte de la pénicilline",
            info1: "1928",
            info2: "",
            commentaire: "La découverte de la pénicilline par Alexander Fleming révolutionne la médecine moderne en introduisant l'ère des antibiotiques."
        },
        {
            titre: "L'alunissage de Apollo 11",
            info1: "20/07/1969",
            info2: "",
            commentaire: "Les astronautes américains Neil Armstrong et Buzz Aldrin deviennent les premiers humains à marcher sur la Lune, marquant une avancée majeure dans l'exploration spatiale."
        },
        {
            titre: "Crise de Suez",
            info1: "1956",
            info2: "",
            commentaire: "Crise internationale provoquée par la nationalisation du canal de Suez par l'Égypte, entraînant une intervention militaire du Royaume-Uni, de la France et d'Israël, et une confrontation avec les États-Unis et l'Union soviétique."
        },
        {
            titre: "Mouvement des droits civiques aux États-Unis",
            info1: "1954",
            info2: "1968",
            commentaire: "Lutte pour l'égalité des droits pour les Afro-Américains, menée par des figures comme Martin Luther King Jr., et marquée par des événements comme le boycott des bus de Montgomery et la marche sur Washington."
        },
        {
            titre: "Guerre du Vietnam",
            info1: "1955",
            info2: "1975",
            commentaire: "Conflit armé entre le Nord Vietnam communiste, soutenu par ses alliés, et le Sud Vietnam, soutenu principalement par les États-Unis. Il est devenu un symbole de la Guerre froide et des luttes anti-impérialistes."
        },
        {
            titre: "Première Guerre mondiale",
            info1: "28/07/1914",
            info2: "11/11/1918",
            commentaire: "La France a joué un rôle central dans la Première Guerre mondiale, avec de lourdes pertes humaines et des batailles décisives comme la bataille de Verdun."
        },
        {
            titre: "Front populaire",
            info1: "1936",
            info2: "1938",
            commentaire: "Coalition de partis de gauche menée par Léon Blum, introduisant des réformes sociales et économiques significatives en France."
        },
        {
            titre: "Révolution iranienne",
            info1: "1979",
            info2: "",
            commentaire: "La révolution islamique en Iran renverse le shah Pahlavi et conduit à l'établissement de la République islamique d'Iran sous la direction de l'ayatollah Khomeini. Cet événement marque un tournant majeur dans la politique et la société iraniennes, avec des répercussions importantes sur l'ensemble de la région."
        },
        {
            titre: "Guerre des Six Jours",
            info1: "05/06/1967",
            info2: "10/06/1967",
            commentaire: "Conflit entre Israël et une coalition de pays arabes, dont l'Égypte, la Jordanie et la Syrie. Israël remporte une victoire rapide, occupant des territoires tels que la Cisjordanie, Gaza, le plateau du Golan et Jérusalem-Est, modifiant profondément la carte politique de la région."
        },
        {
            titre: "Guerre Iran-Irak",
            info1: "22/09/1980",
            info2: "20/08/1988",
            commentaire: "Conflit prolongé entre l'Iran et l'Irak, déclenché par l'invasion irakienne du territoire iranien. Cette guerre dévastatrice entraîne d'énormes pertes humaines et matérielles des deux côtés, avec peu de changements territoriaux significatifs à la fin du conflit."
        },

    ]},
    {
        titre: "Grandes découvertes et exploits sportifs",
        categorie : 1,
        type : 1,
        id : 12,
        commentaire: true,
        jeu: [
           
            {
                titre: "Conquête de l'Everest",
                info1: "29/05/1953",
                info2: "",
                commentaire: "L'alpiniste néo-zélandais Edmund Hillary et le sherpa népalais Tenzing Norgay atteignent pour la première fois le sommet du mont Everest, le plus haut sommet du monde."
            },
            {
                titre: "Traversée de l'Atlantique en avion",
                info1: "20/05/1927",
                info2: "21/05/1927",
                commentaire: "Charles Lindbergh devient le premier pilote à traverser l'Atlantique en solitaire et sans escale, volant de New York à Paris à bord du Spirit of St. Louis."
            },
            {
                titre: "Premier vol supersonique",
                info1: "14/10/1947",
                info2: "",
                commentaire: "Chuck Yeager, pilote de l'armée de l'air américaine, devient le premier à franchir le mur du son à bord du Bell X-1, atteignant une vitesse supérieure à Mach 1."
            },
            {
                titre: "Premier homme sur la Lune",
                info1: "20/07/1969",
                info2: "",
                commentaire: "Neil Armstrong devient le premier homme à marcher sur la Lune lors de la mission Apollo 11, marquant une étape majeure dans l'exploration spatiale."
            },
           
            {
                titre: "Ascension sans oxygène de l'Everest",
                info1: "08/05/1978",
                info2: "",
                commentaire: "Reinhold Messner et Peter Habeler deviennent les premiers alpinistes à atteindre le sommet de l'Everest sans utiliser d'oxygène supplémentaire."
            },
            {
                titre: "Tour du monde en solitaire à la voile",
                info1: "1968",
                info2: "1969",
                commentaire: "Robin Knox-Johnston est le premier à réussir un tour du monde à la voile en solitaire et sans escale, en terminant le Golden Globe Race."
            },
            {
                titre: "Premier tour du monde en ballon",
                info1: "20/03/1999",
                info2: "21/03/1999",
                commentaire: "Bertrand Piccard et Brian Jones accomplissent le premier tour du monde en ballon sans escale à bord du Breitling Orbiter 3."
            },
            {
                titre: "Découverte de l'Amérique par Christophe Colomb",
                info1: "1492",
                info2: "",
                commentaire: "Christophe Colomb, explorateur génois au service de l'Espagne, atteint les îles des Caraïbes le 12 octobre 1492, marquant le début de l'exploration européenne du Nouveau Monde."
            },
            {
                titre: "Tour du monde par Ferdinand Magellan",
                info1: "1519",
                info2: "1522",
                commentaire: "Ferdinand Magellan, navigateur portugais au service de l'Espagne, initie la première circumnavigation du globe, bien qu'il meure en route et que l'expédition soit complétée par Juan Sebastián Elcano."
            },
            {
                titre: "Première expédition à atteindre l'Inde par voie maritime",
                info1: "1497",
                info2: "1499",
                commentaire: "Vasco de Gama, explorateur portugais, est le premier Européen à atteindre l'Inde par voie maritime en contournant le cap de Bonne-Espérance, ouvrant une route maritime directe pour le commerce des épices."
            },
            {
                titre: "Découverte du passage du Nord-Est",
                info1: "1878",
                info2: "1879",
                commentaire: "Le navigateur suédois Adolf Erik Nordenskiöld accomplit la première traversée complète du passage du Nord-Est, reliant l'Europe et l'Asie via l'océan Arctique."
            },
            {
                titre: "Première traversée de l'Antarctique",
                info1: "1911",
                info2: "",
                commentaire: "Roald Amundsen, explorateur norvégien, est le premier à atteindre le pôle Sud géographique, devançant l'expédition britannique menée par Robert Falcon Scott."
            },
            {
                titre: "Exploration du Pacifique par James Cook",
                info1: "1768",
                info2: "1779",
                commentaire: "James Cook, explorateur britannique, mène trois expéditions dans le Pacifique, cartographiant de nombreuses îles et côtes, y compris la Nouvelle-Zélande, l'Australie et Hawaï."
            },
           
            {
                titre: "Exploration du Mississippi par La Salle",
                info1: "1682",
                info2: "",
                "commentaire": "René-Robert Cavelier, sieur de La Salle, explore le fleuve Mississippi jusqu'à son embouchure, revendiquant la région pour la France et nommant le territoire Louisiane."
            }
        ]
    },
    {
        titre: "Les peintures célèbres",
        commentaire: true,
        categorie : 1,
        type : 1,
        id : 13,
        jeu: [
           
            {
                titre: "La Joconde - Léonard de Vinci",
                info1: "1503",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre1.jpg' alt='ordre1'></img>"
            },
            {
                titre: "La Promenade - Monet",
                info1: "1875",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre2.jpg' alt='ordre2'></img>"
            },
            {
                titre: "La Jeune Fille à la perle - Vermeer",
                info1: "1665",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre3.jpg' alt='ordre3'></img>"
            },
            {
                titre: "La Naissance de Vénus - Botticelli",
                info1: "1484",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre4.jpg' alt='ordre4'></img>"
            },
           
            {
                titre: "La Persistance de la mémoire - Salvador Dalí",
                info1: "1931",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre5.jpg' alt='ordre5'></img>"
            },
            {
                titre: "Le Cri - Edvard Munch",
                info1: "1893",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre6.jpg' alt='ordre6'></img>"
            },
            {
                titre: "Le Radeau de La Méduse - Géricault",
                info1: "1818",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre7.jpg' alt='ordre7'></img>"
            },
            {
                titre: "La Nuit étoilée - Van Gogh",
                info1: "1889",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre8.jpg' alt='ordre8'></img>"
            },
            {
                titre: "Guernica - Pablo Picasso",
                info1: "1937",
                info2: "",
                commentaire:"<img src='https://evalquiz.com/imageTest/peintordre9.jpg' alt='ordre9'></img>"
            },
            {
                titre: "Les Ménines -  Velázquez",
                info1: "1656",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre10.jpg' alt='ordre10'></img>"
            },
            {
                titre: "La Liberté guidant le peuple - Delacroix",
                info1: "1830",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre11.jpg' alt='ordre11'></img>"
            },
            {
                titre: "Le Déjeuner sur l'herbe - Manet",
                info1: "1863",
                info2: "",
                commentaire: "<img src='https://evalquiz.com/imageTest/peintordre12.jpg' alt='ordre12'></img>"
            }    
        ]
    },
    {
        "titre": "Les pays et leurs populations",
        "categorie": 2,
        "type": 2,
        "id": 14,
        "commentaire": false,
        "jeu": [
            {
                "titre": "France",
                "info1": "67 M",
                "info2": ""
            },
            {
                "titre": "États-Unis",
                "info1": "333 M",
                "info2": ""
            },
            {
                "titre": "Chine",
                "info1": "1412 M",
                "info2": ""
            },
            {
                "titre": "Inde",
                "info1": "1417 M",
                "info2": ""
            },
            {
                "titre": "Brésil",
                "info1": "215 M",
                "info2": ""
            },
            {
                "titre": "Russie",
                "info1": "145 M",
                "info2": ""
            },
            {
                "titre": "Japon",
                "info1": "126 M",
                "info2": ""
            },
            {
                "titre": "Allemagne",
                "info1": "83 M",
                "info2": ""
            },
            {
                "titre": "Nigeria",
                "info1": "218 M",
                "info2": ""
            },
            {
                "titre": "Mexique",
                "info1": "128 M",
                "info2": ""
            }
        ]
    }
    ,
    {
        "titre": "Les villes du monde et leurs populations",
        "categorie": 2,
        "type": 2,
        "id": 15,
        "commentaire": false,
        "jeu": [
            {
                "titre": "Paris",
                "info1": "2.16 M",
                "info2": ""
            },
            {
                "titre": "New York",
                "info1": "8.34 M",
                "info2": ""
            },
            {
                "titre": "Shanghai",
                "info1": "26.32 M",
                "info2": ""
            },
            {
                "titre": "Mumbai",
                "info1": "20.41 M",
                "info2": ""
            },
            {
                "titre": "Moscou",
                "info1": "12.64 M",
                "info2": ""
            },
            {
                "titre": "Tokyo",
                "info1": "13.96 M",
                "info2": ""
            },
            {
                "titre": "Berlin",
                "info1": "3.64 M",
                "info2": ""
            },
            {
                "titre": "Lagos",
                "info1": "15.37 M",
                "info2": ""
            },
            {
                "titre":"Los Angeles",
                "info1": "4.09 M",
                "info2": ""
            }
        ]
    },
    {
        "titre": "Les villes françaises et leurs populations",
        "categorie": 2,
        "type": 2,
        "id": 16,
        "commentaire": false,
        "jeu": [
            {
                "titre": "Paris",
                "info1": "2160 K",
                "info2": ""
            },
            {
                "titre": "Marseille",
                "info1": "870 K",
                "info2": ""
            },
            {
                "titre": "Lyon",
                "info1": "520 K",
                "info2": ""
            },
            {
                "titre": "Toulouse",
                "info1": "504 K",
                "info2": ""
            },
            {
                "titre": "Nice",
                "info1": "348 K",
                "info2": ""
            },
            {
                "titre": "Nantes",
                "info1": "323 K",
                "info2": ""
            },
            {
                "titre": "Strasbourg",
                "info1": "290 K",
                "info2": ""
            },
            {
                "titre": "Montpellier",
                "info1": "302 K",
                "info2": ""
            },
            {
                "titre": "Bordeaux",
                "info1": "260 K",
                "info2": ""
            },
            {
                "titre": "Lille",
                "info1": "230 K",
                "info2": ""
            }
        ]
    },
        {
            "titre": "Les montagnes les plus hautes de chaque continent",
            "categorie": 2,
            "type": 3,
            "id": 17,
            "commentaire": true,
            "jeu": [
                {
                    "titre": "Mont Everest",
                    "info1": "8848 m",
                    "commentaire": "Asie (Himalaya)"
                },
                {
                    "titre": "K2",
                    "info1": "8611 m",
                    "commentaire": "Asie (Himalaya)"
                },
                {
                    "titre": "Aconcagua",
                    "info1": "6960 m",
                    "commentaire": "Amérique du Sud (Andes)"
                },
                {
                    "titre": "Denali",
                    "info1": "6190 m",
                    "commentaire": "Le Denali, appelé mont McKinley de 1896 à 2015 - Amérique du Nord (Alaska)"
                },
                {
                    "titre": "Kilimandjaro",
                    "info1": "5895 m",
                    "commentaire": "Point culminant de l'Afrique (Tanzanie)"
                },
                {
                    "titre": "Elbrouz",
                    "info1": "5642 m",
                    "commentaire": "Point culminant de l'Europe (Russie)"
                },
                {
                    "titre": "Vinson",
                    "info1": "4892 m",
                    "commentaire": "Point culminant de l'Antarctique"
                },
                {
                    "titre": "Mont Blanc",
                    "info1": "4809 m",
                    "commentaire": "Plus haut sommet d'Europe occidentale et de l'Union européenne (France)"
                },
                {
                    "titre": "Puncak Jaya",
                    "info1": "4884 m",
                    "commentaire": "Plus haut sommet d'Océanie (Indonésie)"
                }
            ]
    },
{
    "titre": "Les plus grands lacs du monde",
    "categorie": 2,
    "type": 4,
    "id": 18,
    "commentaire": true,
    "jeu": [
        {
            "titre": "Mer Caspienne",
            "info1": "371000 km²",
            "commentaire": "Située entre l'Europe et l'Asie. Les mers fermées sont des lacs d'un point de vue géographique."
        },
        {
            "titre": "Lac Supérieur",
            "info1": "82100 km²",
            "commentaire": "Le plus grand des Grands Lacs d'Amérique du Nord"
        },
        {
            "titre": "Lac Victoria",
            "info1": "68800 km²",
            "commentaire": "Le plus grand lac d'Afrique, partagé entre la Tanzanie, l'Ouganda et le Kenya"
        },
        {
            "titre": "Lac Huron",
            "info1": "59600 km²",
            "commentaire": "Un autre des Grands Lacs d'Amérique du Nord"
        },
        {
            "titre": "Lac Michigan",
            "info1": "58000 km²",
            "commentaire": "Situé entièrement aux États-Unis"
        },
        {
            "titre": "Lac Tanganyika",
            "info1": "32900 km²",
            "commentaire": "Deuxième plus grand lac d'Afrique, partagé entre quatre pays"
        },
        {
            "titre": "Lac Baïkal",
            "info1": "31500 km²",
            "commentaire": "Le plus profond lac du monde, situé en Sibérie, Russie"
        },
        {
            "titre": "Lac Malawi",
            "info1": "30044 km²",
            "commentaire": "Troisième plus grand lac d'Afrique, également connu sous le nom de lac Nyasa"
        },
        {
            "titre": "Grand Lac de l'Ours",
            "info1": "31153 km²",
            "commentaire": "Le plus grand lac entièrement au Canada"
        }
    ]
},
{
    "titre": "Les 6 prénoms masculins les plus donnés en 2023",
    "categorie": 3,
    "type": 5,
    "id": 19,
    "commentaire": false,
    "jeu": [
        {
            "titre": "Gabriel",
            "info1": "4527",
        },
        {
            "titre": "Léo",
            "info1": "3454",
        },
        {
            "titre": "Raphaël",
            "info1": "3477",
        },
        {
            "titre": "Maël",
            "info1": "3280",
        },
        {
            "titre": "Louis",
            "info1": "3331",
        },
        {
            "titre": "Noah",
            "info1": "3163",
        },
       
    ]
},
{
    "titre": "Les 6 prénoms féminins les plus donnés en 2023",
    "categorie": 3,
    "type": 5,
    "id": 20,
    "commentaire": false,
    "jeu": [
        {
            "titre": "Louise",
            "info1": "3177",
        },
        {
            "titre": "Ambre",
            "info1": "3168",
        },
        {
            "titre": "Alba",
            "info1": "3088",
        },
        {
            "titre": "Jade",
            "info1": "2891",
        },
        {
            "titre": "Emma",
            "info1": "2663",
        },
        {
            "titre": "Rose",
            "info1": "2375",
        },
       
    ]
},
    {
        "titre": "Les grands films par année depuis 2010",
        "categorie": 3,
        "type": 6,
        "id": 21,
        "commentaire": true,
        "jeu": [
            {
                "titre": "Inception",
                "info1": "2010",
                "commentaire": "Réalisé par Christopher Nolan, un thriller de science-fiction acclamé"
            },
            {
                "titre": "The Artist",
                "info1": "2011",
                "commentaire": "Film muet français réalisé par Michel Hazanavicius, récompensé aux Oscars"
            },
            {
                "titre": "Gravity",
                "info1": "2013",
                "commentaire": "Un drame de science-fiction réalisé par Alfonso Cuarón"
            },
            {
                "titre": "Star Wars - Le Réveil de la Force",
                "info1": "2015",
                "commentaire": "Le Réveil de la Force est le premier film de la troisième trilogie Star Wars."
            },
            {
                "titre": "La La Land",
                "info1": "2016",
                "commentaire": "Comédie musicale réalisée par Damien Chazelle"
            },
            {
                "titre": "Parasite",
                "info1": "2019",
                "commentaire": "Thriller dramatique réalisé par Bong Joon-ho, oscarisé pour le meilleur film"
            },
            {
                "titre": "Dune",
                "info1": "2021",
                "commentaire": "Adaptation de l'œuvre de science-fiction de Frank Herbert, réalisée par Denis Villeneuve"
            },
            {
                "titre": "Top Gun: Maverick",
                "info1": "2022",
                "commentaire": "Suite tant attendue du film culte des années 80, réalisée par Joseph Kosinski"
            },
            {
                "titre": "Oppenheimer",
                "info1": "2023",
                "commentaire": "Biographie historique réalisée par Christopher Nolan, centrée sur la vie de J. Robert Oppenheimer"
            }
        ]
    },
    {
        "titre": "Les grands sites internet et applications",
        "categorie": 3,
        "type": 6,
        "id": 22,
        "commentaire": true,
        "jeu": [
            {
                "titre": "Facebook",
                "info1": "2004",
                "commentaire": "Créé par Mark Zuckerberg, devenu l'un des plus grands réseaux sociaux au monde"
            },
            {
                "titre": "YouTube",
                "info1": "2005",
                "commentaire": "Plateforme de partage de vidéos fondée par Steve Chen, Chad Hurley, et Jawed Karim"
            },
            {
                "titre": "Twitter",
                "info1": "2006",
                "commentaire": "Service de microblogging créé par Jack Dorsey, Biz Stone, et Evan Williams"
            },
            {
                "titre": "Instagram",
                "info1": "2010",
                "commentaire": "Application de partage de photos fondée par Kevin Systrom et Mike Krieger"
            },
            {
                "titre": "Snapchat",
                "info1": "2011",
                "commentaire": "Application de messagerie et de partage de photos éphémères fondée par Evan Spiegel, Bobby Murphy, et Reggie Brown"
            },
            {
                "titre": "TikTok",
                "info1": "2016",
                "commentaire": "Application de partage de vidéos courtes lancée par la société chinoise ByteDance"
            },
            {
                "titre": "Booking.com",
                "info1": "1996",
                "commentaire": "Site de réservation d'hôtels et de logements, l'un des plus grands et anciens dans le domaine"
            },
            {
                "titre": "TripAdvisor",
                "info1": "2000",
                "commentaire": "Site de recommandations et d'avis pour les hôtels, restaurants, et activités de voyage"
            },
            {
                "titre": "BeReal",
                "info1": "2020",
                "commentaire": "Application de partage de photos authentiques où les utilisateurs doivent capturer un moment de leur journée à un moment aléatoire"
            },
            
            {
                "titre": "Threads",
                "info1": "2023",
                "commentaire": "Application de messagerie textuelle lancée par Meta, axée sur les discussions de groupe et la vie quotidienne"
            },
           
        ]
    },
    {
        "titre": "Grands jeux vidéo : des débuts à aujourd'hui",
        "categorie": 3,
        "type": 6,
        "id": 23,
        "commentaire": true,
        "jeu": [
            {
                "titre": "Pong",
                "info1": "1972",
                "commentaire": "Un des premiers jeux vidéo commerciaux, développé par Atari, représentant un jeu de tennis de table simple"
            },
            {
                "titre": "Space Invaders",
                "info1": "1978",
                "commentaire": "Jeu d'arcade classique développé par Tomohiro Nishikado, célèbre pour avoir popularisé le genre des jeux de tir"
            },
            {
                "titre": "Pac-Man",
                "info1": "1980",
                "commentaire": "Jeu d'arcade iconique développé par Namco, connu pour son personnage emblématique."
            },
            {
                "titre": "Tetris",
                "info1": "1984",
                "commentaire": "Jeu de puzzle classique développé par Alexey Pajitnov, reconnu pour son influence durable et sa mécanique simple mais addictive"
            },
            {
                "titre": "Super Mario Bros.",
                "info1": "1985",
                "commentaire": "Jeu de plateforme révolutionnaire développé par Nintendo, introduisant Mario comme personnage principal et établissant le genre des jeux de plateforme"
            },
           
            {
                "titre": "World of Warcraft",
                "info1": "2004",
                "commentaire": "Jeu de rôle en ligne massivement multijoueur développé par Blizzard Entertainment, emblématique du genre MMO"
            },
            {
                "titre": "Minecraft",
                "info1": "2011",
                "commentaire": "Jeu de construction et de survie en monde ouvert, devenu un phénomène culturel avec une grande communauté"
            },
            {
                "titre": "The Legend of Zelda: Breath of the Wild",
                "info1": "2017",
                "commentaire": "Jeu d'action-aventure acclamé, connu pour son monde ouvert et son gameplay innovant"
            },
            {
                "titre": "Among Us",
                "info1": "2018",
                "commentaire": "Jeu multijoueur en ligne devenu populaire et adapté en série télé."
            },
            {
                "titre": "Cyberpunk 2077",
                "info1": "2020",
                "commentaire": "Jeu de rôle en monde ouvert, connu pour son univers futuriste et ses mécaniques immersives"
            },
            {
                "titre": "Elden Ring",
                "info1": "2022",
                "commentaire": "Jeu de rôle et d'action en monde ouvert, salué pour sa profondeur et ses défis"
            }
        ]
    },
    {
        "titre": "Les 6 titres les plus écoutés sur Spotify",
        "categorie": 3,
        "type": 7,
        "id": 24,
        "commentaire": false,
        "jeu": [
            {
                "titre": "Blinding Lights - The Weeknd",
                "info1": "4.34 Mrd",
            },
            {
                "titre": "As It Was - Harry Styles",
                "info1": "3.09 Mrd",
            },
            {
                "titre": "Shape of You - Ed Sheeran",
                "info1": "3.90 Mrd",
            },
            {
                "titre": "Someone You Loved - Lewis Capaldi",
                "info1": "3.41 Mrd",
            },
            {
                "titre": "Sunflower - Post Malone",
                "info1": "3.34 Mrd",
            },
            {
                "titre": "Starboy - The Weeknd",
                "info1": "3.27 Mrd",
            },
           
        ]
    },

    
    
    
    
    

    
    
    
  

]



export { dataOrdre };