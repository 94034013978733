import React from 'react';
import MenuHaut from './MenuHaut';
import Routeur from './Routeur';
import './app.css';

import ReactGA4 from "react-ga4";


ReactGA4.initialize("G-916D7SW5D6", { legacyDimensionMetric: false, gtagOptions: { send_page_view: true } })

function App() {
 

  return (
      <div><MenuHaut>
          <div className="margePrincipale">
              <Routeur></Routeur>
              </div>
      </MenuHaut>
      </div>
  );
}

export default App;
