
import React, { Component } from 'react';
import { Button } from 'antd';



export default class Saisie extends Component {

    constructor() {
        super();
        this.tabNombre = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
        this.state = {
            nombre: ''
        }
    }

    clickNombre = (event) => {
        const id = event.currentTarget.id;
        if (this.props.nombre.length < 8) {
        this.props.clickNombre(id);
        }
       
        event.preventDefault();

    }


    clickReset = () => {
        this.props.clickReset();
    }

    clickFin = () => {
        console.log(this.state.nombre);
 
        if (isNaN(parseInt(this.state.nombre))) {
            this.props.clickFin(0);
        }
        else {
            this.props.clickFin(parseInt(this.state.nombre));
        }
    }

    render() {

        return <div>
            <h3>Entrez votre réponse</h3>
            <div className="zoneSaisie">{this.props.nombre}</div>
            <ul className="calculatrice">
                {this.tabNombre.map((nombre, i) => <li key={nombre} id={nombre} onClick={this.clickNombre} className="nombre">{nombre}</li>)}
            </ul>
            <div className="centre margeHaut20"><Button size="large" type="primary" onClick={this.clickFin}>OK</Button></div>
            <div className="centre margeHaut20"><Button size="large" type="danger" onClick={this.clickReset}>Reset</Button></div>
        </div>

    }
}