import React, { useState, useEffect } from 'react';
import { Gauge } from '@ant-design/charts';


function GaugeResultat(props) {

  var [percent, setPercent] = useState(0.0);
  var [rangeColor, setRangeColor] = useState('#d9d9d9');
  var color = ['#f44336', '#31df38'];

  var getColor = function getColor(percent) {
    return percent < 0.9 ? color[0] : color[1];
  };
  var config = {
    percent,
    range: { color: rangeColor },
    indicator: {
      pointer: { style: { stroke: '#D0D0D0' } },
      pin: { style: { stroke: '#D0D0D0' } },
    },
    axis: {
      label: {
            formatter: function formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: { count: 3 },
    },
    statistic: {
      content: {
        style: { fontSize: '25px'},
        formatter: function formatter(_ref) {
          return 'Points';
        },
      }

    },
    animation: false,
  };
  useEffect(() => {
    var data = percent;
    console.log(props.nbPoints);
      var interval = setInterval(function () {
          if (data >= props.nbPoints / 100) {
              setPercent(props.nbPoints / 100);
          clearInterval(interval);
              props.messageFin();
      } else {
        data += 0.005;
        setPercent(data);
        setRangeColor(getColor(data));
      }
    },1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return <div className="flexGauge"><div className="gauge"><Gauge {...config}/></div></div>;
};

export default GaugeResultat;