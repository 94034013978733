import React, { Component } from 'react';
import {Col, Row} from 'antd';


export default class Question extends Component
{
   
    render () 
    {
        return <div className="margeBas40  questionSuite">
            <div className="moyenneFont" dangerouslySetInnerHTML={{ __html: this.props.texte }}></div>
       
        </div>
   }

   
}