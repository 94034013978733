import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Presentation from './Presentation';
//import JeuQuiz from '../../jeux/quiz/JeuQuiz';
import JeuPourcentage from '../../jeux/pourcentage/JeuPourcentage';
import JeuSuite from '../../jeux/suite/JeuSuite';
import TestSuite from '../../jeux/testSuite/TestSuite';
import JeuOrdre from '../../jeux/ordre/JeuOrdre';



export default class Routeur extends Component {

    render() {
        return (
            <Switch >
            <Route exact path='/' component={Presentation} />
                <Route path='/menu' component={Presentation} />
                {/* <Route path={'/' + DataQuiz.getNom() + '/:id'} render={(props) => <JeuQuiz {...props} keyProp={'jeuxQuiz'} key={Math.floor(Math.random() * 1000)} />} />
                 */}
                   {/* <Route path={'/quiz-royal/:id'}  component={JeuQuiz} /> */}
                   <Route path={'/juste-nombre/:categorie'}  render={(props) => <JeuPourcentage {...props} keyProp={'jeuxPourcentage'} key={Math.floor(Math.random() * 1000)} />} />
                   <Route path={'/suite'}  render={(props) => <JeuSuite {...props} keyProp={'jeuxSuite'} key={Math.floor(Math.random() * 1000)} />} />
                   <Route path={'/ordre/:no'}  render={(props) => <JeuOrdre {...props} keyProp={'ordre'} key={Math.floor(Math.random() * 1000)} />} />
                   <Route path={'/tt'}  render={(props) => <TestSuite {...props} keyProp={'testSuite'} key={Math.floor(Math.random() * 1000)} />} />
               
           
           </Switch>  
        );
    }
}