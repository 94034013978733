import React, { useState } from 'react'
import { useDrag } from 'react-dnd';
import ItemPreview from './ItemPreview'

export const Carte = ({element, game }) => {
    const {pos,titre, place} = element;

    const [{ isDragging }, dragRef] = useDrag({
        type: 'carte',
        item: () => {
            game.modifierAfficheCommentaire(false);
            return {pos, place, titre}
        },
        canDrag: (item) => {return true
        },
        
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    })

    return (<React.Fragment>
            <ItemPreview />
            <div ref={dragRef} className={ (titre !== '' ?  'carteOrdre '  : 'cartreOrdreVide ') + (isDragging ? 'carteOrdreOpaque' : '')}> { element.titre !== "" && <div   dangerouslySetInnerHTML={{ __html: titre }} >
        </div>}</div></React.Fragment>
    )
}