import React, { useState } from 'react';
import { CaseHaut } from './CaseHaut';


export function CartesHaut({game}) 
{
    const [tabCartesHaut, setCartesHaut] = useState(game.tabCartesHaut)
    game.ajouteObserverCartesHaut(setCartesHaut);



    return <div className="cartesTabOrdre">{tabCartesHaut.map((element, i) => <CaseHaut game={game} key={i} element={element}></CaseHaut>)}</div>
    
}