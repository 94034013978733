import React, { Component } from 'react';
import { Input, Button} from 'antd';

import Ad from '../../components/commun/AdSense';
import Confetti from "react-confetti";
import { readFirstName, addFirstName } from '../../components/commun/localStorage';

export default class Resultat extends Component {


    constructor(props) {
        super(props);
        let prenom = readFirstName() || '';
     
        this.state = {
            prenom,
            afficherPrenom : prenom=== '' ? true : false
        }
    }
    componentDidMount() {
     
        console.log(this.state.prenom);
        if (this.state.prenom !== '')
        {
            this.envoyerMessage(this.state.prenom);
        }

    }
  

    ok = () => {
        if (this.state.prenom !== '' ) {
            addFirstName(this.state.prenom);
            this.envoyerMessage(this.state.prenom);
        }        
        this.setState({ afficherPrenom: false });
    }
    ajouterPrenom = () => {
        if (this.state.afficherPrenom) {
            return  <div className="centre"><p>Entrez votre prénom pour être inscrit au tableau d'honneur.</p>
                <Input maxLength={18} style={{ width: '200px' }}  placeholder="Votre prénom" onChange={(event) => this.setState({ prenom: event.target.value })} value={this.state.prenom}></Input>
                <Button  type='primary' onClick={this.ok}>OK</Button></div>

        }
    }

    envoyerMessage = (prenom) =>
    {
        let url = new URL(process.env.REACT_APP_URL_QUIZAJOUTERTABLEAU);
        var data = new FormData();
        data.append('prenom', prenom);
        data.append('type', this.props.typeExo)
        fetch(url, {
            method: "POST",
            body: data
        })
    }

    render()
    {
        return <div>
            {<Confetti />}
            <div className="centre">
            {this.ajouterPrenom()}

        </div> <Ad></Ad></div>
    }
}