import React, { useState } from 'react';
import { CaseBas } from './CaseBas';


export function CartesBas({game}) 
{
  const [tabCartesBas, setTabCartesBas] = useState(game.tabCartesBas)
  game.ajouteObserverCartesBas(setTabCartesBas);

    return <div className="cartesTabOrdre">{tabCartesBas.map((element, i) => <CaseBas  game={game} id={i} key={i} element={element}></CaseBas>)}</div>
    
}