import React, { useState } from 'react'
import { useDrop } from 'react-dnd';
import { Carte } from './Carte';


export const CaseBas = ({ game, element }) => {
    const { pos, place, commentaire } = element;
    const [{ isOver }, dropRef] = useDrop({
        accept: 'carte',
        drop: (item) => {
            game.modifierCartes(item, pos, "bas");
        },
        canDrop: (item) => {
            return game.canDropTabBas(pos);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    })


    function clic() {
        let info = game.obtenirTitre();

        if (info.commentaire) {
            game.modifierCommentaire(commentaire, element.titre);
            game.modifierAfficheCommentaire(true);
        }
    }

    return (
        <div ref={dropRef} onClick={clic} id={pos} className={game.verifierEtatBas(pos) ? 'caseVideOrdre' : 'caseOrdre'}> <Carte game={game} element={element}></Carte>
        </div>
    )
}