import React, { Component } from 'react';
import Reponses from './Reponses';
import Question from './Question';
import { message, Button } from 'antd';
import { Helmet } from 'react-helmet';
import CompteRebours from '../../components/commun/CompteRebours';
import Paliers from './Paliers';
import ModalReponse from './ModalReponse';
import ResultatGagne from './ResultatGagne';
import { Link} from 'react-router-dom';


const palierTab = [{ etape: '1', type: "", fait: false }, { etape: '2', type: "", fait: false }, { etape: '3', type: "", fait: false }, { etape: '4', type: "", fait: false }, { etape: '5', type: "", fait: false },
{ etape: '6', type: "", fait: false }, { etape: '7', type: "", fait: false }, { etape: '8', type: "", fait: false }, { etape: '9', type: "", fait: false }, { etape: '10', type: "", fait: false }]


export default class JeuSuite extends Component {

    constructor(props) {
        super(props)

        this.fin = false;
        this.explication = '';
        this.perdu = false;
        this.serieEnCours = 0;
        this.perdu = false;
        this.qcm = null;
        this.state = {
            question: "",
            reponseTab: [],
            explication: '',
            afficheResultat: false,
            afficheExplication: false,
            palierTab,
            afficheRebours: false,
            afficheFin: false
        };
    }

    modificationReponse = (no) => {
        let reponseTab = [];
        console.log(this.qcm);
        for (let index = 0; index < this.qcm[no].reponses.length; index++) {
            reponseTab.push({ repJoueur: 'vide', choix: this.qcm[no].reponses[index], bonneReponse: this.qcm[no].bonneReponse === index ? true : false })

        }
        return reponseTab;
    }

    creerQuestionnaire = async () => {
        let url = new URL(process.env.REACT_APP_URL_QUIZOBTENIRQUESTIONS);
        const reponse = await fetch(url);

        if (reponse.ok) {
            this.qcm = await reponse.json();
            console.log(this.qcm);
            let reponseTab = this.modificationReponse(0);
            let nouveauPalierTab = [...palierTab];
            for (let index = 0; index < this.qcm.length; index++) {
                nouveauPalierTab[index].type = this.qcm[index].type;
                nouveauPalierTab[index].fait = false;
            }
            nouveauPalierTab[this.serieEnCours].fait = true;

            console.log(reponseTab);
            this.setState({
                question: this.qcm[0].question,
                reponseTab,
                palierTab: nouveauPalierTab,
                afficheRebours: true
            });
        }
        else {
            alert("Désolé, il y a un problème.")
            window.location.href = "/"
        }
    }

    async componentDidMount() {
        await this.creerQuestionnaire();

    }
    suite = () => { console.log(this.serieEnCours);
            console.log(this.qcm.length);
        if (this.serieEnCours == this.qcm.length) {
           
     //   if (this.serieEnCours == 2) {
            this.finJeu();
        }
        else {
            this.fin = false;
            let reponseTab = this.modificationReponse(this.serieEnCours);
            console.log(reponseTab);
            this.setState({
                question: this.qcm[this.serieEnCours].question,
                reponseTab,
                afficheRebours: true
            });
        }

    }


    finJeu = () => {

        this.setState({
            afficheFin: true
        });
    }

    verifierReponseQcm = (id) => {
        if (this.fin) return;
        this.fin = true;
        let nouveauReponseTab = [...this.state.reponseTab];
        let nouveauPalierTab = [...this.state.palierTab];


        if (nouveauReponseTab[id].bonneReponse) {
            message.success('Bravo, bonne réponse', 4, this.suite);
            nouveauReponseTab[id].repJoueur = 'bonneRep';  
              nouveauPalierTab[this.serieEnCours].fait = true;
            this.serieEnCours++;
        



        }
        else {
            message.error('Mauvaise réponse', 5, this.finJeu);
            let index = nouveauReponseTab.findIndex(x => x.bonneReponse);
            nouveauReponseTab[id].repJoueur = 'choix';
            nouveauReponseTab[index].repJoueur = 'bonneRep';
            this.perdu = true;
            this.serieEnCours = 0;


        }
        this.setState({ reponseTab: nouveauReponseTab, palierTab: nouveauPalierTab, afficheRebours: false });
    }

    remiseA0 = () =>
        {
             this.fin = false;
        this.explication = '';
        this.perdu = false;
       
        }

    recommencer = async () => {
         
 this.serieEnCours = 0;
        await this.creerQuestionnaire();
       
        this.setState({
            explication: '',
            afficheResultat: false,
            afficheExplication: false,
            afficheFin: false
        }, this.remiseA0);

    }

    finPerdu = () => {
        return <div className='questionSuite margeBas30'>
            Malheureusement vous avez perdu.
            <div className='centre margeHaut10'><Button type="primary" onClick={this.recommencer}>Recommencer</Button></div>
            <div className='margeHaut10'>Nous proposons d'autres formes de quiz avec des questions différentes.</div>
            <div> <a href="https://concours.evalquiz.com/presculture">Jeu de plateau de la culture générale</a></div>
            <div>Pour les amateurs d'histoire</div>
            <div><Link to={'/juste-nombre/histoire'}>Trouver la bonne année historique</Link></div>

        </div>
    }

    finGagne = () =>
    {

    }

    finTimer = () => {
        this.perdu = true;
        this.finJeu();
    }
    render() {
        return <React.Fragment>  
             <Helmet>
             <body style="background-repeat: no-repeat;background-attachment: fixed; background-image: radial-gradient(circle farthest-corner at 92.4% 11.7%, rgb(0, 137, 164) 0%, rgba(2, 83, 185, 1) 100.2%);"/>
           
            <title>Les dix à la suite</title>
            <meta name="description" content="Un quiz de culture générale parfait pour les fans de Qui veut gagner des millions ? et de Question pour un champion"  />
        </Helmet>

            <div>
                <div className='plateauSuite'> 
                <div className='paliersSuite'>
                        <Paliers palierTab={this.state.palierTab}></Paliers>
                    </div>
                    <div className='jeuSuite'>
                        {this.state.afficheFin ?
                            (this.perdu ? this.finPerdu() :
                                <div className='questionSuite'><ResultatGagne recommencer={this.recommencer}></ResultatGagne></div>) :
                            <React.Fragment>   <Question texte={this.state.question}>  </Question>
                                {this.state.reponseTab.length !== 0 && <Reponses reponseTab={this.state.reponseTab} verifierReponseQcm={this.verifierReponseQcm}></Reponses>}
                                {this.state.afficheExplication && <ModalReponse explication={this.explication} finJeu={this.finJeu}></ModalReponse>}
                                {this.state.afficheRebours && <CompteRebours temps={20} finTimer={this.finTimer}></CompteRebours>}
                            </React.Fragment>}
                    </div>
                   
                </div>
            </div></React.Fragment>
    }


}