import React, { Component } from 'react';
import { Col, Row } from 'antd';


export default class Reponses extends Component {


    constructor(props) {
        super(props);
        let max = 0;
        this.texteQcm = 'texteSuite texteQcm3';
        max = props.reponseTab[0].choix.length;
        for (let index = 1; index < props.reponseTab.length; index++) {
            if (max < props.reponseTab[index].choix.length) {
                max = props.reponseTab[index].choix.length;
    
            }
        }

        if (max < 50) {
            this.texteQcm = 'texteSuite texteQcm1';
        } else if (max < 100) {
            this.texteQcm = 'texteSuite texteQcm2';
        }

    }

    creerClasse = (reponse) => {

        let classe = 'caseReponseQcm ';
        if (reponse.choix[0] === '<' && reponse.choix[1] === 'i') {
            classe += 'imageQcmSuite '
        }
        else {

            classe += this.texteQcm + ' ';
        }

        if (reponse.repJoueur === 'choix') {
            classe += 'choixJoueur ';
        }
        if (reponse.repJoueur === 'bonneRep') {
            classe += 'bonneRep ';
        }
        if (reponse.repJoueur === 'supReponseQcm') {
            classe += 'supReponseQcm ';
        }


        return classe;

    }
    verifierReponseQcm = (event) => {

        const id = parseInt(event.currentTarget.id);
        this.props.verifierReponseQcm(id);
    }

    render() {
        return <div className='reponsesSuite'>
            {this.props.reponseTab.map((reponse, i) =><div id={i} key={i} onClick={this.verifierReponseQcm}
                className={this.creerClasse(reponse)}
                dangerouslySetInnerHTML={{ __html: reponse.choix }}></div>)}
       </div>



    }


}