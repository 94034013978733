import React, { Component } from 'react';
import { Menu} from 'antd';
import { HomeOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom'


export default class MenuHaut extends Component {
    render() {
        return <div>

            <Menu
                mode="horizontal"
                theme='light'
                style={{ backgroundColor: "#afe4e2" }}
            >
                <Menu.Item key="home">
                    <a href="https://evalquiz.com"><HomeOutlined style={{ marginLeft: '7px', fontSize: '25px' }}/></a>
                </Menu.Item>
                <Menu.Item key="quizRoyal">
          <Link to='/'>
           Quiz Royal
          </Link>
        </Menu.Item> 
        <Menu.Item key="anglais">
          <a href="https://anglais.evalquiz.com/">
           Anglais
          </a>
        </Menu.Item> 
                <Menu.Item key="orthographe">
        <a href="https://orthographe.evalquiz.com/">
          Orthographe
          </a>
        </Menu.Item>
        <Menu.Item key="culture">
          <a href="https://evalquiz.com/tests/culture">
           Culture
          </a>
        </Menu.Item>
        <Menu.Item key="math">
          <a href="https://evalquiz.com/tests/math">
           Calcul
          </a>
        </Menu.Item>
        <Menu.Item key="logique">
          <a href="https://evalquiz.com/tests/logique">
           QI
          </a>
        </Menu.Item>      
        <Menu.Item key="cerebral">
          <a href="https://cerebral.evalquiz.com">
           Entrainement cérébral
          </a>
        </Menu.Item> 

            </Menu>
            {this.props.children}
        </div>
    }
}