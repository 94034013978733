import React from 'react-dnd';
import { Carte } from './Carte';
import { useDrop } from 'react-dnd';

export const CaseHaut = ({ game, element }) => {

    const { pos, place, commentaire } = element;
    const [{ isOver }, dropRef] = useDrop({
        accept: 'carte',
        drop: (item) => {
            game.modifierCartes(item, pos, "haut");
        },
        canDrop: (item) => {
            return game.canDropTabHaut(pos);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    })

    function clic() {
        let info = game.obtenirTitre();

        if (info.commentaire && commentaire != '') {
            game.modifierCommentaire(commentaire, element.titre);
            game.modifierAfficheCommentaire(true);
        }
    }
    return (
        <div ref={dropRef} onClick={clic} className={game.verifierEtatHaut(pos) ? 'caseVideOrdre' : 'caseOrdre'}> <Carte game={game} element={element}></Carte>
        </div>
    )
}