import React, { Component } from 'react';


export default class Paliers extends Component {


  
    render() {
        return this.props.palierTab.map((info, i) =><div className={info.fait ?'boxPalierSuite palierFaitSuite':  'boxPalierSuite'} key={i+500}>
            <div> {info.etape}</div><div className='smallFont nomPalierSuite'>{info.type}</div>  </div>)
       



    }


}