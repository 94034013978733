import { Modal } from 'antd';
import React, { Component, Fragment } from 'react';

export default class ModalReponse extends Component {


  state = { visible: true };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
    this.props.finJeu();
  };
  handleCancel = e => {
    this.setState({
      visible: false,
    });
    this.props.finJeu();
  };



  render() {
    return (
      <Modal closable={false} 
        cancelButtonProps={{ style: { display: 'none' } }}
        title="Commentaire"
        open={this.state.visible}
        onOk={this.handleOk}
      >

        <div dangerouslySetInnerHTML={{ __html: this.props.explication }}></div> 
      </Modal>

    );
  }
}