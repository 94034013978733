function addGame(name, value) {
    let tab = [];
    if (value != null && name != null) {
        if (localStorage.getItem(name) !== null) {
 
            tab = JSON.parse(localStorage.getItem(name));
            tab =  tab.filter(x => x.no !== value) 
        }
      
        tab.push({no: value, action: 'fait'});
        localStorage.setItem(name, JSON.stringify(tab));
    }
}

function replayGame(name, value) {
    if (value != null && name != null) {
        if (localStorage.getItem(name) !== null) {      
            let tab = JSON.parse(localStorage.getItem(name));
            let jeu = tab.find(x => x.no === value);
            if (jeu !== undefined) {
                jeu.action = 'refaire';
                localStorage.setItem(name, JSON.stringify(tab));
            }
        }
    }
}

function readGames(name) {
    if (localStorage.getItem(name) !== null) {
        return JSON.parse(localStorage.getItem(name));
    }
    return [];
}

function addFirstName(value)
{   
    localStorage.setItem("prenom",value);   
}

function readFirstName()
{

    return localStorage.getItem("prenom");
}

export { addGame, readGames, replayGame,addFirstName, readFirstName};

