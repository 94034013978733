 import React, { Component } from 'react';
import Saisie from './Saisie';
import Gauge from './Gauge';
import Resultat from './Resultat';
import { Button } from 'antd';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

export default class JeuPourcentage extends Component {

    constructor(props) {
        super();
        this.categorie = props.match.params.categorie;
        this.changerCategorie();
        this.donneesJeu = null;
        this.state = {
            question: '',
            fin: false,
            messageFin: '',
            infoScore: '',
            noQuestion: 0,
            score: 0,
            afficheResultat: false,
            nbQuestions: 0,
            finScore: false,
            nbReponse: 1,
            finReponse: false,
            nombre: '',
            tabEssai: []
        };

        this.perdu = false;
        this.reponse = '';
        this.nbErreur = 0;
        this.nbErreurSigne = 0;
        this.stop = false;
        this.score = 0;
        this.nbPoints = 0;

        this.tabScore = [{ annee: 2000, points: 20 }, { annee: 1900, points: 10 }, { annee: 1800, points: 4 }, { annee: 1000, points: 1 }, { annee: 0, points: .75 }]

    }

    changerCategorie() {
        if (this.categorie === 'histoire' || this.categorie === 'histoire-monde' || this.categorie === 'histoire-france') {
            this.categorie = "histoire";
            this.titre = "Le quiz des dates historiques";

        }

        if (this.categorie === 'geographie') {
            this.categorie = "art";
            this.titre = "Géographie";

        }
        if (this.categorie === 'divers') {
            this.titre = "Les divertissements";
        }
        if (this.categorie === 'mode') {
            this.titre = "La mode féminine";
        }
    }

    async componentDidMount() {

        let url = new URL(process.env.REACT_APP_URL_QUIZLIREQUESTION);
        var data = new FormData();
        data.append('categorie', this.categorie);
        fetch(url, {
            method: "POST",
            body: data
        }).then(res => res.json())
            .then(res => {
                this.donneesJeu = res;
                this.setState({ question: this.donneesJeu[0].question, nbQuestions: this.donneesJeu.length });
                this.reponse = this.donneesJeu[0].reponse;
                this.explication = this.donneesJeu[0].explication;

            }
            );
    }

    messageJeu = (type) => {
        let messageFin = '';
        if (type !== 'histoire') {
            messageFin = "Tu t'es trompé de " + this.nbErreur;
        }
        else {
            if (this.state.nbReponse < 3) {
               let msgDebut = "Ta date est " + (this.nbErreurSigne === '+' ? "supérieure" : "inférieure");
               let msgFin = " ans à la date de l'événement."
               let tabDate = [1000, 500, 400, 300, 200,100,75, 50, 20, 15, 10, 5, 3, 1];
               let tabIcone = ['😭', '😔', '😞', '😢', '😟','😩','😫','😞', '😕', '😐', '😊', '🙂', '😃', '😄']
               let index;
               let messageAns = '';
               for (index = 0; index < tabDate.length; index++) {
                if (tabDate[index] == this.nbErreur)
                {
                    messageAns = " de " + tabDate[index];
                    break;
                }
                if (tabDate[index] < this.nbErreur)
                {
                    messageAns = " de plus de " + tabDate[index];
                    break;
                }
                
               }
                messageFin = msgDebut + messageAns + msgFin + ' '+ tabIcone[index];
            }
            else {
                messageFin = "Tu t'es trompé de " + this.nbErreur + (this.nbErreur === 1 ? ' an.' : ' ans.');

            }
        }
        return messageFin;
    }

    clickNombre = (nombre) => {

        this.setState({ nombre: this.state.nombre + nombre });

    }

    messageFinHistoire = (reponseJoueur) => {

        this.nbErreur = Math.abs(this.reponse - reponseJoueur)
        this.nbErreurSigne = this.reponse - reponseJoueur > 0 ? '-' : '+';
        let messageFin = '';
        if (this.nbErreur === 0) {
            messageFin = 'Bravo, tu as trouvé la bonne année !';
        }
        else {
            messageFin = this.messageJeu('histoire');
        }
        let i = 0;
        while (this.reponse < this.tabScore[i].annee) {
            i++;
        }
        this.nbPoints = this.nbErreur * this.tabScore[i].points >= 100 ? 0 : 100 - (this.nbErreur * this.tabScore[i].points);;

        return messageFin;
    }

    messageFinAutre = (reponseJoueur) => {
        this.nbErreur = Math.abs(this.reponse - reponseJoueur)
        let messageFin = '';
        if (this.nbErreur === 0) {
            messageFin = 'Bravo, tu as trouvé le bon nombre !';
        }
        else {
           
            messageFin = this.messageJeu('autre');
        }
        if (this.nbErreur > this.reponse) {
            this.nbPoints = 0;
        }
        else {
            this.nbPoints = Math.ceil(100 - ((this.nbErreur / this.reponse) * 100));
        }


        return messageFin;

    }

    clickFin = () => {
        let reponseJoueur = 0;
        if (!isNaN(parseInt(this.state.nombre))) {
            reponseJoueur = this.state.nombre;
        }

        let messageFin = '';
        if (this.categorie === 'histoire') {
            messageFin = this.messageFinHistoire(reponseJoueur);
            let nouveauTabEssai = [...this.state.tabEssai];
            nouveauTabEssai.push(reponseJoueur);
            if (this.state.nbReponse === 3 || this.nbPoints === 100) {
                this.setState({ nbReponse: 1, nombre: '', fin: true, messageFin, finReponse: false, tabEssai: nouveauTabEssai });
            }
            else {

                this.setState({ nbReponse: this.state.nbReponse + 1, nombre: '', finReponse: true, messageFin, tabEssai: nouveauTabEssai });
            }
        }
        else {
            messageFin = this.messageFinAutre(reponseJoueur);
            this.setState({ nbReponse: 1, nombre: '', fin: true, messageFin, finReponse: false });
        }


    }

    continuer = () => {
        let noQuestion = this.state.noQuestion;


        if (noQuestion < this.donneesJeu.length - 1) {
            noQuestion++;
            this.reponse = this.donneesJeu[noQuestion].reponse;
            this.explication = this.donneesJeu[noQuestion].explication;


            this.setState({
                fin: false,
                question: this.donneesJeu[noQuestion].question,
                messageFin: '',
                infoScore: '',
                noQuestion,
                nombre: '',
                tabEssai: []
            });
        }
        else {
            this.setState({
                afficheResultat: true,
            })
        }
    }

    messageFin = () => {

        this.setState({
            infoScore: this.nbPoints,
            score: this.state.score + this.nbPoints, finScore: true
        })
    }

    clickReset = () => {
        this.setState({ nombre: '' });
    }
    render() {

        if (this.stop) {
            return (<Redirect to='/'></Redirect>);
        }

        return <div>
            <Helmet>
                <title>{this.titre}</title>
                <meta name="description" content="Saurez-vous retrouver une date historique à partir d'une photo ou le prix d'un article de mode ? Différents thèmes sont disponibles : culture, divertissement..." />
            </Helmet>
            {this.state.afficheResultat ? <Resultat typeExo={'ns' + (this.categorie === 'art' ? 'geographie' : this.categorie)} score={this.state.score}></Resultat> :
                <React.Fragment>

                    <div className="jeuPourcentage">

                        <div className="plateauPourcentage1 paddingGaucheDroite">
                            <h1>{this.titre}</h1>
                            <div>Question : {this.state.noQuestion + 1} / {this.state.nbQuestions}  <span className="margeGauche30">Score : {this.state.score}</span>
                                <div className="margeHaut20 moyenneFont noSelect" dangerouslySetInnerHTML={{ __html: this.state.question }}></div></div>
                        </div>
                        <div className="plateauPourcentage2 paddingGaucheDroite">
                            {this.state.finReponse && <div className="centre moyenneFont">{this.state.messageFin}</div>}
                            {this.state.fin ?
                                <div> <div className="centre moyenneFont"><div>La bonne réponse est : </div><b>{this.reponse}</b></div><div className="centre moyenneFont">{this.state.messageFin}</div>{this.explication != null && this.explication !== '' && <div dangerouslySetInnerHTML={{ __html: this.explication }}></div>}
                                    <Gauge nbPoints={this.nbPoints} messageFin={this.messageFin}></Gauge> {this.state.finScore && <React.Fragment>
                                        <div className="centre moyenneFont">Ton score : <b>{this.state.infoScore}</b></div>
                                        <div className="centre"><Button className="centre" type="primary" size="large" onClick={this.continuer}>Continuer</Button></div>
                                    </React.Fragment>}</div>
                                : <Saisie clickFin={this.clickFin} clickReset={this.clickReset} clickNombre={this.clickNombre} nombre={this.state.nombre}></Saisie>}
                            {this.state.tabEssai.map((annee, i) => <div key={i + 500}><span>Essai {i + 1} / 3 : {annee}</span></div>)}
                        </div>

                    </div>

                </React.Fragment>}
        </div>
    }
}