import React, { Component } from 'react';
import { Input, Button} from 'antd';
import {creerLiens} from './Lien';
import Ad from '../../components/commun/AdSense';
import Confetti from "react-confetti";
import { readFirstName, addFirstName } from '../../components/commun/localStorage';

export default class Resultat extends Component {


    constructor(props) {
        super(props);
        let prenom = readFirstName() || '';
        this.state = {
            prenom,
            afficherPrenom : prenom=== '' && this.props.score >= 490 ? true : false
        }
    }
    componentDidMount() {
        if (this.state.prenom !== '' && this.props.score >= 490)
        {
            this.envoyerMessage(this.state.prenom);
        }

    }
    phraseFinale()
    {
        var msg = "";
        if (this.props.score === 500)
        {
            msg = "Fantastique ! Tu as réussi à obtenir le meilleur score possible. Tu es un champion(ne). Ton nom est inscrit sur le tableau d'honneur.";
        }
        else if (this.props.score >= 490)
        {
            msg= "Presque parfait ! Tu as fait un très bon score. Ton nom est inscrit sur le tableau d'honneur.";
        }
        else if (this.props.score > 400)
        {
            msg = "Excellent. Tu as fait un très bon score.";
        } else if (this.props.score > 250)
        {
            msg = "Bravo. Tu as fait mieux que la moyenne";
        }
        else
        {
          msg= "N'hésite pas à rejouer pour faire mieux."
        }
        
        return msg;
    }

    ok = () => {
        if (this.state.prenom !== '' ) {
            addFirstName(this.state.prenom);
            this.envoyerMessage(this.state.prenom);
        }        
        this.setState({ afficherPrenom: false });
    }
    ajouterPrenom = () => {
        if (this.state.afficherPrenom) {
            return  <div className="centre"><p>Entrez votre prénom pour être inscrit au tableau d'honneur.</p>
                <Input maxLength={18} style={{ width: '200px' }}  placeholder="Votre prénom" onChange={(event) => this.setState({ prenom: event.target.value })} value={this.state.prenom}></Input>
                <Button  type='primary' onClick={this.ok}>OK</Button></div>

        }
    }

    envoyerMessage = (prenom) =>
    {
        let url = new URL(process.env.REACT_APP_URL_QUIZAJOUTERTABLEAU);
        var data = new FormData();
        data.append('prenom', prenom);
        data.append('type', this.props.typeExo)
        fetch(url, {
            method: "POST",
            body: data
        })
    }

    render()
    {
        return <div>
            {this.props.score > 490 &&<Confetti />}
            <h1>Ton résultat</h1>
            <div className="centre">
           
            <div>Ton score pour les 5 questions : <b className="moyenneFont violet">{this.props.score} / 500</b></div>
            <p className="margeHaut10 grandeFont">{this.phraseFinale()}</p>
            {this.ajouterPrenom()}
        
        
      {creerLiens(this.props.typeExo)}
        </div> <Ad></Ad></div>
    }
}