import {  usePreview } from 'react-dnd-multi-backend'

import React from 'react';

const ItemPreview = () => {
  const preview = usePreview()
  if (!preview.display) {
    return null
  }
  const {itemType, item, style} = preview;
  console.log(item);



  return (
    <div style={style} className='previewOrdre'>{item.titre}  
    </div>
  );
};

export default ItemPreview;